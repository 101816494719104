import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    '& .MuiButton-root': {
      textTransform: 'none',
    },
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
