import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(9.75),
  },
  container: {
    position: 'relative',
    zIndex: -1,
  },
  image: {
    width: '100%',
    objectFit: 'cover',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '20px',
    overflow: 'hidden',
  },
  playerWrapper: {
    borderRadius: '50%',
    position: 'relative',
    paddingTop: '56.25%',
  },
}));

export default useStyles;
