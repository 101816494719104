import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Payment.PaymentCalculationScreen.title',
    defaultMessage: 'Now Let’s See What you can Afford!',
  },
  clientProfile: {
    id: 'Payment.PaymentCalculationScreen.clientprofile',
    defaultMessage: 'Client Profile',
  },
  buyer: {
    id: 'Payment.PaymentCalculationScreen.buyer',
    defaultMessage: 'Buyer',
  },
  coBuyer: {
    id: 'Payment.PaymentCalculationScreen.coBuyer',
    defaultMessage: 'Co-Buyer:',
  },
  name: {
    id: 'Payment.PaymentCalculationScreen.name',
    defaultMessage: 'Name',
  },
  firstName: {
    id: 'Payment.PaymentCalculationScreen.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'Payment.PaymentCalculationScreen.lastName',
    defaultMessage: 'Last name',
  },
  phoneNumber: {
    id: 'Payment.PaymentCalculationScreen.phoneNumber',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'Payment.PaymentCalculationScreen.email',
    defaultMessage: 'Email',
  },
  additionalComments: {
    id: 'Payment.PaymentCalculationScreen.additionalComments',
    defaultMessage: 'Notes/Reminders (up to 200 characters per note)',
  },
  addCoBuyer: {
    id: 'Payment.PaymentCalculationScreen.addCoBuyer',
    defaultMessage: 'Add co-buyer',
  },
  addCoSeller: {
    id: 'Payment.PaymentCalculationScreen.addCoSeller',
    defaultMessage: 'Add co-seller',
  },
  calculator: {
    id: 'Payment.PaymentCalculationScreen.calculator',
    defaultMessage: 'Calculator',
  },
  subCalculator: {
    id: 'Payment.PaymentCalculationScreen.subCalculator',
    defaultMessage: 'Make sure you update all fields before calculating.',
  },
  deleteConfirmation: {
    id: 'Payment.PaymentCalculationScreen.deleteConfirmation',
    defaultMessage: 'Delete this card. Are you sure?',
  },
  confirm: {
    id: 'Payment.PaymentCalculationScreen.confirm',
    defaultMessage: 'Yes',
  },
  notConfirm: {
    id: 'Payment.PaymentCalculationScreen.notConfirm',
    defaultMessage: 'No',
  },
  allClients: {
    id: 'Payment.PaymentCalculationScreen.allClients',
    defaultMessage: 'View All Clients',
  },
  currentStatus: {
    id: 'Payment.PaymentCalculationScreen.currentStatus',
    defaultMessage: 'Current Status',
  },
  add: {
    id: 'Payment.PaymentCalculationScreen.addComment',
    defaultMessage: 'Add',
  },
  placeholderSelect: {
    id: 'Payment.PaymentCalculationScreen.placeholderSelect',
    defaultMessage: 'Select'
  },
  calculationTooltip: {
    id: 'Payment.PaymentCalculationScreen.calculationTooltip',
    defaultMessage: "Don't forget to update all fields before doing the calculations"
  }
});
