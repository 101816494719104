/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import Container from '../../Common/Container';
import messages from './messages';

export default function PrivacyPolicy() {
  return (
    <Container>
      <Box textAlign="center" mt={6}>
        <Typography variant="h5">
          <FormattedMessage {...messages.header} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.startIntro} />
          {' '}
          <Box color="blue" fontWeight="600" component="span">
            <Link to="/">
              <FormattedMessage {...messages.homeLink} />
            </Link>
          </Box>
          {' '}
          <FormattedMessage {...messages.endIntro} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.oneHeader} />
        </Typography>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.oneBasicPOne} />
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h6">
            <FormattedMessage {...messages.oneBasicPTwo} />
          </Typography>
        </Box>
      </Box>
      <Box mt={1.5} ml={4} mb={0.5}>
        <Typography variant="h1">
          <FormattedMessage {...messages.onePunktA} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneAOne} />
        </Typography>
      </Box>
      <Box mt={1.5} ml={4} mb={0.5}>
        <Typography variant="h1">
          <FormattedMessage {...messages.onePunktA} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneBOne} />
        </Typography>
      </Box>
      <Box mt={1.5} ml={4} mb={0.5}>
        <Typography variant="h1">
          <FormattedMessage {...messages.onePunktC} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneCOne} />
        </Typography>
      </Box>
      <Box mt={1.5} ml={4} mb={0.5}>
        <Typography variant="h1">
          <FormattedMessage {...messages.onePunktD} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneDOne} />
        </Typography>
      </Box>
      <Box mt={1.5} ml={4} mb={0.5}>
        <Typography variant="h1">
          <FormattedMessage {...messages.onePunktE} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneEOne} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.onePunktF} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneFOne} />
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneFTwo} />
        </Typography>
      </Box>
      <Box mt={2} mb={4}>
        <Typography variant="h6">
          <FormattedMessage {...messages.oneFThree} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.twoHeader} />
        </Typography>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.twoBasicOne} />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.twoPunktA} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.twoAOne} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.twoPunktB} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.twoBOne} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.twoPunktC} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.twoCOne} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.twoPunktD} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.twoDOne} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.twoPunktE} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.twoEOne} />
        </Typography>
        <Box mt={2}>
          <Typography variant="h6">
            <FormattedMessage {...messages.twoETwo} />
          </Typography>
        </Box>
        <Box mt={2} ml={6} color="#636670">
          <ul>
            <li>
              <Typography variant="h6">
                <FormattedMessage {...messages.twoEListOne} />
              </Typography>
            </li>
            <li>
              <Typography variant="h6">
                <FormattedMessage {...messages.twoEListTwo} />
              </Typography>
            </li>
            <li>
              <Typography variant="h6">
                <FormattedMessage {...messages.twoEListThree} />
              </Typography>
            </li>
            <li>
              <Typography variant="h6">
                <FormattedMessage {...messages.twoEListFour} />
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.threeHeader} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.threePunktA} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.threeAOne} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.threePunktB} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.threeBOne} />
        </Typography>
      </Box>
      <Box mb={2}>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.threePunktC} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.threeCOne} />
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.fourHeader} />
        </Typography>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.fourBasicOne} />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.fiveHeader} />
        </Typography>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.fiveBasicOne} />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.sixHeader} />
        </Typography>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.sixBasicOne} />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.sevenHeader} />
        </Typography>
      </Box>
      <Box>
        <Box mt={1.5} ml={4} mb={0.5}>
          <Typography variant="h1">
            <FormattedMessage {...messages.sevenPunktA} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <FormattedMessage {...messages.sevenAOne} />
        </Typography>
        <Box>
          <Typography variant="h6" mt={2}>
            <FormattedMessage {...messages.sevenATwo} />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">
          <FormattedMessage {...messages.eightHeader} />
        </Typography>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.eightBasicOne} />
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h6">
            <FormattedMessage {...messages.eightBasicTwo} />
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.eightBasicThree} />
          </Typography>
        </Box>
      </Box>
      <Box mb={4}>
        <Typography variant="h4">
          <FormattedMessage {...messages.nineHeader} />
        </Typography>
        <Box>
          <Typography variant="h6">
            <FormattedMessage {...messages.nineBasicOne} />
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
