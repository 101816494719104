import qs from 'qs';

const clientFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
  email: 'email',
};
const DEFAULT_CURRENCY = 'USD';

const APPRAISAL_FIREWALL_URL = `https://www.appraisalfirewall.com/AFDesktop/?${qs.stringify({
  managerid: '3d88b2ca-b654-4161-bc7c-5f31c010ae3f',
  entry: 'e65a',
})}`;

const TOP_PRICE_PERCENT = 10;

export {
  clientFields,
  APPRAISAL_FIREWALL_URL,
  DEFAULT_CURRENCY,
  TOP_PRICE_PERCENT,
};
