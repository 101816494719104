import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  viewButton: {
    marginLeft: theme.spacing(5),
  },
  columnContainer: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.lightGray,
  },
  columnTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.secondary.lightGray}`,
    borderRight: 'none',
    borderTop: 'none',
  },
  cardContainer: {
    overflowX: 'hidden',
    '&:last-child': {
      '& .simplebar-track': {
        right: -15,
      },
    },
    '& .simplebar-horizontal': {
      height: 0,
    },
    '& .simplebar-scrollbar': {
      '&:hover': {
        cursor: 'pointer',
      },
      width: 31,
      '&:before': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        width: 15,
        borderRadius: '5px',
        left: 8,
        padding: `0 ${theme.spacing(1)}`,
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    '& .simplebar-track': {
      width: 31,
      display: 'flex',
      justifyContent: 'center',
      '&:before': {
        content: '" "',
        width: 1,
        height: '100%',
        backgroundColor: theme.palette.secondary.lightGray,
      },
    },
  },
  searchBar: {
    backgroundColor: 'white',
    maxWidth: '241px',
  },
  sortBy: {
    backgroundColor: 'white',
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 65px)',
    maxWidth: '241px',
  },
  sortValue: {
    color: theme.palette.secondary.placeholderGray,
    fontSize: '12px',
    lineHeight: 1.5,
    fontWeight: 400,
    opacity: 1,
  },
}));

export default useStyles;
