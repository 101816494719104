import PropTypes from 'prop-types';

const calculationsPropTypes = PropTypes.shape({
  loanAmount: PropTypes.arrayOf(PropTypes.number),
  principalInterest: PropTypes.arrayOf(PropTypes.number),
  taxesInsurance: PropTypes.arrayOf(PropTypes.number),
  mortgageInsurance: PropTypes.arrayOf(PropTypes.number),
  totalEstimatedPayment: PropTypes.arrayOf(PropTypes.number),
  desiredPayment: PropTypes.arrayOf(PropTypes.number),
  betweenWithDesired: PropTypes.arrayOf(PropTypes.number),
  requiredIncome: PropTypes.arrayOf(PropTypes.number),
});

export default calculationsPropTypes;
