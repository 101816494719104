import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ClientCreateScreen from '../../components/User/ClientCreateScreen';
import { AuthContext } from '../../common/authContext';
import { createClient } from '../../services/client.service';
import { Client } from '../../models/client.model';
import { TenantContext } from '../../common/tenantContext';
import { AnalyticsContext } from '../../common/analyticsContext';
import { AnalyticEventNames } from '../../common/analyticEventNames.enum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ClientCreateScreenContainer({ history }: {history: any}) {
  const [error, setError] = useState(null);
  const { authUser } = useContext(AuthContext);
  const { _id } = authUser ?? {};
  const { tenant } = useContext(TenantContext);
  const tenantId = tenant?._id;
  const {trackEvent} = useContext(AnalyticsContext);

  const createNewClient = async ({
    firstName, lastName, phone, email, type, coBuyers, isPrincipal,
  }: Partial<Client>) => {
    setError(null);
    const data: Partial<Client> = {
      firstName,
      lastName,
      phone,
      type,
      realtorId: _id,
      tenantId,
      createdAt: new Date(),
      isPrincipal,
      email: email?.toLowerCase(),
      status: 'potential',
      archive: false,
    };
    if (coBuyers && data.type === 'buyer') {
      data.coBuyers = coBuyers;
    } else if (coBuyers && data.type === 'seller') {
      data.coBuyers = coBuyers;
    }
    const newClient = await createClient(data);
    trackEvent(
      AnalyticEventNames.ClientCreated, 
      {
        _id: newClient._id,
        email: newClient.email,
        isPrincipal, 
        type,
        createdBy: authUser?._id,
        tenantId,
        tenantName: tenant?.name,
        status: newClient.status
      }
    );
    trackEvent(AnalyticEventNames.ClientStatusUpdated, {
      _id: newClient._id,
      status: newClient.status,
      tenantId: tenantId,
      tenantName: tenant?.name,
      updatedBy: authUser?._id
    });
    return newClient?._id;
  };

  const handleCreate = async (allData: {isCoBuyer: boolean, type: string, buyer: Partial<Client>, coBuyer: Partial<Client>, setSubmitting: (isSubmitting: boolean) => void}) => {
    const {
      isCoBuyer, type, buyer, coBuyer, setSubmitting,
    } = allData;
    let coBuyers: string[] | undefined;
    try {
      if (isCoBuyer) {
        const coBuyerId = await createNewClient({
          ...coBuyer, type, isPrincipal: false,
        });
        coBuyers = [coBuyerId];
      }
      const clientId = await createNewClient({
        ...buyer, type, coBuyers, isPrincipal: true,
      });
      history.push(`/clients/${clientId}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      let message = err.message ?? String(err);
      if (message === 'Email already exist') {
        message = 'Client with that email already exist';
      }
      setError(message);
      setSubmitting(false);
    }
  };

  return (
    <ClientCreateScreen onCreate={handleCreate} error={error} />
  );
}

ClientCreateScreenContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
