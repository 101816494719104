import { AccessToken, Account } from '../models';
import { getToken, validateAndExtractData } from './api.base.service';

export const login = async ({
  email,
  password
}: {
  email?: string;
  password?: string;
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}account/login`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    }
  );
  const login: AccessToken = await validateAndExtractData(response);
  if (login?.access_token) {
    localStorage.setItem('realgenius:token', JSON.stringify(login));
  }
  return login;
};

export const getAccount = async (id: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}account/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': getToken()
      }
    }
  );
  const account: Account = await validateAndExtractData(response);
  return account;
};

export const patchAccount = async (id: string, data: Partial<Account>) => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}account/${id}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'authorization': getToken()
      },
      body: JSON.stringify(data)
    }
  );
  const responseData = await validateAndExtractData(response);
  return responseData;
};

export const signup = async (account: Partial<Account>) => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}account/signup`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(account)
    }
  );
  const login: AccessToken = await validateAndExtractData(response);
  if (login?.access_token) {
    localStorage.setItem('realgenius:token', JSON.stringify(login));
  }
  return login;
};


