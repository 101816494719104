import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      minWidth: 150,
    },
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    '& .MuiSlider-markLabel': {
      '&[data-index="1"]': {
        left: 'calc(100% - 30px) !important',
      },
    },
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  inputLabel: {
    position: 'relative',
    marginBottom: theme.spacing(0.5),
  },
  inputBase: {
    border: `1px solid ${theme.palette.secondary.lightGray}`,
    borderRadius: '6px',
    padding: theme.spacing(1.25),
    '& input': {
      padding: 0,
    },
  },
  inputBaseError: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  selectInput: {
    border: `1px solid ${theme.palette.secondary.lightGray}`,
    borderRadius: '6px',
    backgroundColor: 'white',
    padding: theme.spacing(1.25),
    marginTop: '0 !important',
    '&:before': {
      content: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
  disableMarginBottom: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
}));

export default useStyles;
