import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { AccessToken } from '../models';

interface BaseEffectResponse<T> {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  data: T | null;
  setData: Dispatch<SetStateAction<T | null>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute: (requestFunction: (...params: any) => Promise<T>) => Promise<T>;
}

export function useApiEffect<T>(): BaseEffectResponse<T> {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<T | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const execute = async (requestFunction: (...params: any) => Promise<T>) => {
    try {
      setIsLoading(true);
      const data = await requestFunction();
      setData(data);
      setIsLoading(false);
      return data;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    data,
    setData,
    execute: useCallback(execute, [])
  };
}

export const getToken = () => {
  const accessToken: AccessToken = JSON.parse(
    localStorage.getItem('realgenius:token') ?? '{}'
  );
  return `Bearer ${accessToken?.access_token ?? ''}`;
};

export const validateAndExtractData = async (response: Response) => {
    if (response.status == 500) {
      throw 'Server error';
    }
    if (response.status == 401) {
      const res = await response.json();
      throw res?.message ?? 'Invalid token';
    }
    if (response.status === 422) {
      const res = await response.json();
      throw res?.message ?? 'Unprocessable Entity';
    }
    if (response.ok) {
        return response.json();
    }
}
