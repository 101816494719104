import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import CircularProgress from '../CircularProgress';
import useStyles from './styles';

export default function SubmitButton({ isSubmitting, text, disabled }) {
  const classes = useStyles();

  return (
    <div className={classes.buttonWrapper}>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting || disabled}
        size="large"
      >
        {text}
      </Button>
      {isSubmitting
      && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};
