import React from 'react';
import Twitter from '@material-ui/icons/Twitter';
import LinkedIn from '@material-ui/icons/LinkedIn';
import IconButton from '../../Common/IconButton';
import Box from '../../Common/Box';
import useStyles from './styles';
import MuiLink from '../../Common/Link';

export default function Sidebar() {
  const classes = useStyles();
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection={{ sm: 'row', md: 'column' }}
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <MuiLink href="https://twitter.com/REALGENIUS_Home">
        <IconButton color="primary" className={classes.socialButton}>
          <Twitter className={classes.socialIcon} />
        </IconButton>
      </MuiLink>
      <MuiLink href="https://www.linkedin.com/company/real-genius/?viewAsMember=true">
        <IconButton color="primary" className={classes.socialButton}>
          <LinkedIn className={classes.socialIcon} />
        </IconButton>
      </MuiLink>
    </Box>
  );
}
