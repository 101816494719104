import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Payment.PaymentCalculationForm.title',
    defaultMessage: 'Now Let’s See What you can Afford!',
  },
  comfortablePrice: {
    id: 'Payment.PaymentCalculationForm.comfortablePrice',
    defaultMessage: 'What price range are you comfortable with?',
  },
  monthlyPayment: {
    id: 'Payment.PaymentCalculationForm.monthlyPayment',
    defaultMessage: 'Desired monthly payment',
  },
  downPayment: {
    id: 'Payment.PaymentCalculationForm.downPayment',
    defaultMessage: 'Funds Available to Close',
  },
  termOfLoan: {
    id: 'Payment.PaymentCalculationForm.termOfLoan',
    defaultMessage: 'Term of loan',
  },
  isMortgageInsurance: {
    id: 'Payment.PaymentCalculationForm.isMortgageInsurance',
    defaultMessage: 'Mortgage insurance is required',
  },
  mortgageInsurance: {
    id: 'Payment.PaymentCalculationForm.mrtgageInsurance',
    defaultMessage: 'Mortgage insurance',
  },
  requiredMonthlyPayment: {
    id: 'Payment.PaymentCalculationForm.requiredMonthlyPayment',
    defaultMessage: 'Desired monthly payment is required',
  },
  requiredDownPayment: {
    id: 'Payment.PaymentCalculationForm.requiredDownPayment',
    defaultMessage: 'Funds Available to Close is required',
  },
  requiredTermOfLoan: {
    id: 'Payment.PaymentCalculationForm.requiredTermOfLoan',
    defaultMessage: 'Term of loan is required',
  },
  requiredPercentOfFundsTowardsLoan: {
    id: 'Payment.PaymentCalculationForm.requiredPercentOfFundsTowardsLoan',
    defaultMessage: 'Percent of funds toward loan is required',
  },
  requiredUserInterestRate: {
    id: 'Payment.PaymentCalculationForm.requiredInterestRate',
    defaultMessage: 'Interest rate is required',
  },
  clickCalculate: {
    id: 'Payment.PaymentCalculationForm.clickCalculate',
    defaultMessage: 'Click to Calculate',
  },
  summary: {
    id: 'Payment.PaymentCalculationForm.summary',
    defaultMessage: 'Great, shall we go see what you can Afford?',
  },
  desiredPrice: {
    id: 'Payment.PaymentCalculationForm.desiredPrice',
    defaultMessage: 'Desired price',
  },
  topPrice: {
    id: 'Payment.PaymentCalculationForm.topPrice',
    defaultMessage: 'Top price',
  },
  percentOfFundsTowardsLoan: {
    id: 'Payment.PaymentCalculationForm.percentOfFundsTowardsLoan',
    defaultMessage: '% of Funds towards Loan',
  },
  userInterestRate: {
    id: 'Payment.PaymentCalculationForm.userInterestRate',
    defaultMessage: 'Interest rate',
  },
});
