import { Tenant } from '../models/tenant.model';
import { getToken, validateAndExtractData } from './api.base.service';

export const getTenant = async (id: string): Promise<Tenant> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}tenant/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': getToken()
      }
    }
  );
  const tenant: Tenant = await validateAndExtractData(response);
  return tenant;
};

export const getTenantPublic = async (slug: string): Promise<Tenant> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}tenant/${slug}/limited`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const tenant: Tenant = await validateAndExtractData(response);
  return tenant;
};