/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import Button from '../../Common/Button';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import messages from './messages';
import useStyles from './styles';

export default function InvitationCard({ user }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const handleStartNow = () => history.push(user ? '/welcome' : '/sign-in');

  return (
    <>
      <Box display="flex" flexDirection="column" mr={{ xs: 0, sm: 12.5 }}>
        <Box color={theme.palette.textPrimary.textBlack} pb={1.5}>
          <Typography variant="h3">
            <FormattedMessage {...messages.welcomeTo} />
          </Typography>
        </Box>
        <Box pb={1.5}>
          <Typography variant="h3" color="primary">
            <FormattedMessage {...messages.brand} />
          </Typography>
        </Box>
        <Box className={classes.textContainer}>
          <Typography variant="h6">
            <FormattedMessage {...messages.text1} />
          </Typography>
        </Box>
        <Box className={classes.btn} display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          <Button variant="contained" color="primary" size="large" onClick={handleStartNow}>
            { user ? <FormattedMessage {...messages.welcome} /> : <FormattedMessage {...messages.login} />}
          </Button>
        </Box>
      </Box>
    </>
  );
}


InvitationCard.propTypes = {
  user: PropTypes.object,
};

InvitationCard.defaultProps = {
  user: undefined,
};
