/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ClickAwayListener, MenuItem, Tooltip, useTheme } from '@material-ui/core';
import Header from '../../../containers/User/HeaderContainer';
import Container from '../../Common/Container';
import Paper from '../../Common/Paper';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import Grid from '../../Common/Grid';
import Divider from '../../Common/Divider';
import PaymentCalculationForm from '../PaymentCalculationForm';
import PaymentCalculationFormSeller from '../PaymentCalculationFormSeller';
import PaymentDashboard from '../PaymentDashboard';
import messages from './messages';
import useStyles from './styles';
import clientPropTypes from '../../Client/clientPropTypes';
import calculationsPropTypes from '../calculationsPropTypes';
import Button from '../../Common/Button';
import CreateUserForm from '../../Common/CreateUserForm';
import ErrorSnackbar from '../../Common/ErrorSnackbar';
import TextField from '../../Common/TextField';
import PhoneFormat from '../../User/ClientCreateScreen/PhoneFormat';
import IconButton from '../../Common/IconButton';
import { DeleteIcon, EditIcon } from '../../../common/Icons/index';
import AddIcon from '../../Common/AddIcon';
import CheckBox from '../../Common/Checkbox';
import FormControlLabel from '../../Common/FormControlLabel';
import Dialog from '../../Common/Dialog';
import DialogTitle from '../../Common/DialogTitle';
import DialogActions from '../../Common/DialogActions';
import TypeAvatar from '../../Common/TypeAvatar';
import InfoIcon from '@material-ui/icons/Info';
import PaymentPartners from '../PaymentPartners/PaymentPartners';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { AnalyticsContext } from '../../../common/analyticsContext';
import { AnalyticEventNames } from '../../../common/analyticEventNames.enum';
import { AuthContext } from '../../../common/authContext';

const driverObj = driver();

const initialCoBuyerValues = {
  type: 'buyer',
  coBuyer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
};

const statusOptions = [
  {key: 'Potential', value: 'potential'}, 
  {key: 'Active', value: 'active'}, 
  {key: 'Under Contract', value: 'under contract'}, 
  {key: 'Closed', value: 'closed'}
];
export default function PaymentCalculationScreen({
  onBuyerDelete,
  initialValues,
  client,
  coBuyers,
  calculations,
  onCalculate,
  isMortgageInsurance,
  isLoading,
  onUpdateUser,
  onBuyerUpdate,
  updateUserError,
  onCreateComment,
  tenant
}) {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const [showCoBuyerForm, setShowCoBuyerForm] = useState(false);
  const [buyersValues, setBuyersValues] = useState([]);
  const [inEditMode, setInEditMode] = useState(false);
  const [status, setStatus] = useState(client.status || '');
  const [comment, setComment] = useState('');
  const [archive, setArchive] = useState(client.archive || false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { trackEvent } = useContext(AnalyticsContext);
  const { authUser } = useContext(AuthContext);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleNextStepsButton = () => {
    driverObj.highlight({element: '#next-steps'});
  };

  const handleCalculateButton = () => {
    driverObj.highlight({element: '#payment-dashboard'});
  };
  

  initialCoBuyerValues.type = client.type;

  const onCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  useEffect(() => {
    if (client) {
      const newValues = [...coBuyers, client];
      setBuyersValues(newValues);
    }
    // eslint-disable-next-line
  }, [client, coBuyers]);
  useEffect(() => {
    setStatus(client.status);
    // eslint-disable-next-line
  }, [client.status]);

  useEffect(() => {
    setArchive(client.archive);
    // eslint-disable-next-line
  }, [client.archive]);

  const onCommentSubmit = (e) => {
    e.preventDefault();
    const commentObj = { comment, date: new Date() };
    const findedBuyer = buyersValues.find((buyerValues) => buyerValues._id === client._id);
    findedBuyer.comments = !findedBuyer.comments
      ? [commentObj]
      : [...findedBuyer.comments, commentObj];
    onCreateComment(findedBuyer);
    setComment('');
  };

  const onChangeValues = (event, id) => {
    const findedBuyer = buyersValues.find((buyerValues) => buyerValues._id === id);
    if (!findedBuyer) return;
    findedBuyer[event.target.name] = event.target.value;
    const oldBuyersFiltered = buyersValues.filter((buyerValues) => buyerValues._id !== id);

    setBuyersValues([...oldBuyersFiltered, findedBuyer]);
    onUpdateUser(findedBuyer);
  };
  const onStatusChange = (event) => {
    const updatedBuyer = { ...client };
    updatedBuyer.status = event.target.value;
    setStatus(event.target.value);
    trackEvent(AnalyticEventNames.ClientStatusUpdated, {
      _id: updatedBuyer._id,
      status: updatedBuyer.status,
      tenantId: updatedBuyer.tenantId,
      tenantName: tenant.name,
      updatedBy: authUser._id
    });
    onUpdateUser(updatedBuyer);
  };
  const onArchiveChange = () => {
    const updatedBuyer = { ...client };
    updatedBuyer.archive = !archive;
    setArchive(!archive);
    onUpdateUser(updatedBuyer);
  };

  // eslint-disable-next-line max-len
  const toggleCoBuyerForm = () => setShowCoBuyerForm((showCoBuyerFormState) => !showCoBuyerFormState);

  const renderBuyer = (innerBuyer) => {
    const findedBuyer = buyersValues.find((buyerValues) => buyerValues._id === innerBuyer._id);
    if (!findedBuyer) return null;
    return (
      <Grid container direction='row' key={findedBuyer._id} spacing={2}>
        <Grid item xs={12} md={4} spacing={2} container direction='column' alignItems="flex-start">
          <Grid item>
            <Typography variant="h1">
              <FormattedMessage {...messages.name} />
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center" height="100%">
              {inEditMode ? (
                <>
                  <Box>
                    <TextField
                      value={findedBuyer.firstName}
                      name="firstName"
                      InputProps={{
                        style: { fontSize: '0.875rem' },
                      }}
                      onChange={(event) => onChangeValues(event, findedBuyer._id)}
                    />
                  </Box>
                  <TextField
                    value={findedBuyer.lastName}
                    name="lastName"
                    InputProps={{
                      style: { fontSize: '0.875rem' },
                    }}
                    onChange={(event) => onChangeValues(event, findedBuyer._id)}
                  />
                </>
              ) : (
                <Typography variant="h6" className={classes.longWord}>
                  {`${findedBuyer.firstName} ${findedBuyer.lastName}`}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} spacing={2} container direction='column' alignItems="flex-start">
          <Grid item>
            <Typography variant="h1">
              <FormattedMessage {...messages.phoneNumber} />
            </Typography>
          </Grid>
          <Grid item style={{width: '100%'}}>
            <Box display="flex" alignItems="center" height="100%">
              <TextField
                name="phone"
                InputProps={{
                  style: { fontSize: inEditMode ? '14px' : '18px' },
                  disableUnderline: !inEditMode,
                  inputComponent: PhoneFormat
                }}
                value={findedBuyer.phone || ''}
                fullWidth
                onChange={(event) => {
                  if (!inEditMode) return;
                  return onChangeValues(event, findedBuyer._id);
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} spacing={2} container direction='column' alignItems="flex-start">
          <Grid item>
            <Typography variant="h1" component="span">
              <FormattedMessage {...messages.email} />
            </Typography>
          </Grid>
          <Grid item style={{width: '100%'}}>
            <Box display="flex" alignItems="center" height="100%" width='100%'>
              {inEditMode ? (
                <TextField
                  value={findedBuyer.email || ''}
                  name="email"
                  InputProps={{
                    style: { fontSize: '0.875rem' },
                  }}
                  fullWidth
                  onChange={(event) => onChangeValues(event, findedBuyer._id)}
                />
              ) : (
                <Typography variant="h6" className={classes.longWord}>
                  {findedBuyer.email}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderExpansionPanel = () => (
    <Box mt={3} p={0}>
      <Box mb={2}>
        <Typography variant="h1" component="span">
          <FormattedMessage {...messages.additionalComments} />
        </Typography>
      </Box>
      <form onSubmit={onCommentSubmit}>
        <Box
          px={1.5}
          py={1}
          borderRadius="6px"
          border={`1px solid ${theme.palette.secondary.lightGray}`}
          display="flex"
          alignItems="center"
        >
          <TextField
            placeholder="Type new comment"
            fullWidth
            multiline
            minRows={2}
            maxRows={2}
            InputProps={{ disableUnderline: true }}
            className={classes.textAreaP}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button type="submit" variant="outlined" size="large" 
            color="primary" onClick={() => { trackEvent(AnalyticEventNames.RealtorInteraction, {realtorId: authUser?._id, action: 'Add comment'}) }}>
            <FormattedMessage {...messages.add} />
          </Button>
        </Box>
      </form>
      <Box
        className={clsx(
          classes.commentsContainer,
          client.comments && client.comments > 3 && classes.scrollableY,
        )}
      >
        {client.comments
          && client.comments.map((commentItem, index) => {
            let epoch = commentItem.date;
            if (commentItem.date) {
              epoch = new Date(commentItem.date);
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid container space={5} className={classes.commentCard} key={`comment-${index}`}>
                <Grid item sm={4} md={2} xs={5}>
                  <Typography variant="body1">{format(epoch, 'MM/dd/yyyy')}</Typography>
                </Grid>
                <Grid item sm={7} md={9} xs={6}>
                  <Typography variant="body1">{commentItem.comment}</Typography>
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </Box>
  );

  const renderClientProfileHeader = () => (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Box mr={2.5}>
            <TypeAvatar type={client.type ?? ''} />
          </Box>
          <Typography variant="h5">
            <FormattedMessage {...messages.clientProfile} />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => setInEditMode((state) => !state)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => setOpenDeleteConfirmation(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Box width="100%" py={1}>
        <Divider />
      </Box>
    </Box>
  );

  const renderStatusPanel = () => (
    <Box className={classes.statusContainer}>
      <Typography variant="h1" component="span">
        <FormattedMessage {...messages.currentStatus} />
      </Typography>
      <Box mb={2}></Box>
      <TextField
        color="primary"
        variant="outlined"
        size="small"
        placeholder={intl.formatMessage(messages.placeholderSelect)}
        select
        value={status}
        onChange={onStatusChange}
        inputProps={{
          className: classes.sortValue,
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.key}
          </MenuItem>
        ))}
      </TextField>
      <Box my={2}>
        <Divider />
      </Box>
      <Box>
        <FormControlLabel
          control={<CheckBox checked={archive} onChange={onArchiveChange} />}
          label="Archive:"
          labelPlacement="start"
          className={classes.archiveCheckBox}
        />
      </Box>
    </Box>
  );

  const hasCobuyer = !!coBuyers.length;
  return (
    <>
      <Header />
      <Container maxWidth="xl" className={classes.container}>
        <Dialog
          open={openDeleteConfirmation}
          onClose={onCloseDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage {...messages.deleteConfirmation} />
          </DialogTitle>
          <DialogActions>
            <Button onClick={onBuyerDelete} color="primary" autoFocus>
              <FormattedMessage {...messages.confirm} />
            </Button>
            <Button onClick={onCloseDeleteConfirmation} color="primary">
              <FormattedMessage {...messages.notConfirm} />
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={5}>
              <Box mb={1} display="flex">
                {renderClientProfileHeader()}
              </Box>
              <Grid container>
                <Grid
                  item
                  container
                  alignItems='center'
                >
                  <Grid item xs={12} md={hasCobuyer ? 12 : 9}>
                    {renderBuyer(client)}
                  </Grid>
                  <>
                    {hasCobuyer ? null : (
                      <Grid item xs={12} md={3} className={classes.addCobuyerContainer}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={toggleCoBuyerForm}
                          startIcon={<AddIcon />}
                        >
                          {client.type === 'seller' ? (
                            <FormattedMessage {...messages.addCoSeller} />
                          ) : (
                            <FormattedMessage {...messages.addCoBuyer} />
                          )}
                        </Button> 
                      </Grid>
                    )}
                  </>
                  {
                    hasCobuyer 
                      ? <Grid item xs={12}><Box mt={3}>{renderBuyer(coBuyers[0])}</Box></Grid> 
                      : null
                  }
                  {
                    showCoBuyerForm 
                      ? <Grid item>
                          <Box py={2}>
                            <CreateUserForm
                              initialValues={initialCoBuyerValues}
                              onSubmitCallback={onBuyerUpdate}
                              closeForm={toggleCoBuyerForm}
                              cancelButton
                              inPayment
                            />
                          </Box>
                        </Grid> 
                      : null
                  } 
                </Grid>
                <Grid item xs={12} sm={4}>
                  {renderStatusPanel()}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {renderExpansionPanel()}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={5}>
              <Box p={3}>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Typography variant="h5">
                    <FormattedMessage {...messages.calculator} />
                  </Typography>
                  <Box ml={2}>
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Box display='flex' flexDirection='column' justifyContent='center'>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement='top'
                        aria-setsize={14}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <Typography variant="body1">
                            <FormattedMessage {...messages.calculationTooltip} />
                          </Typography>
                        }
                      >
                        <InfoIcon onClick={handleTooltipOpen}/>
                      </Tooltip>
                    </Box>
                    </ClickAwayListener>
                  </Box>
                </Box>
                <Typography variant="body1">
                  <FormattedMessage {...messages.subCalculator} />
                </Typography>
                <Box width="100%" py={3}>
                  <Divider />
                </Box>
                {client.type === 'buyer' ? (
                  <PaymentCalculationForm
                    key={'BuyerCalc' + calculations?._id}
                    initialValues={initialValues}
                    onCalculate={(data) => onCalculate({...data, handleCalculateButton})}
                    isMortgageInsurance={isMortgageInsurance}
                  />
                ) : (
                  <PaymentCalculationFormSeller
                    key={'SellerCalc' + calculations?._id}
                    initialValues={initialValues}
                    onCalculate={(data) => onCalculate({...data, handleCalculateButton})}
                  />
                )}
              </Box>
            </Paper>
          </Grid>
          {calculations?._id && Object.keys(calculations).length > 0 && (
            <>
              <Grid item xs={12} md={7}>
                <PaymentDashboard
                  calculations={calculations}
                  isLoading={isLoading}
                  isBuyer={client.type === 'buyer'}
                  showNextSteps={!!tenant.partners?.length}
                  onNextStepsCallback={handleNextStepsButton}
                  driverObj={driverObj}
                />
              </Grid>
              {
                tenant?.partners?.length ? 
                  <Grid item xs={12} md={5}>
                    <PaymentPartners partners={tenant.partners} driverObj={driverObj} clientId={client?._id} partnerType={client?.partnerType}/>
                  </Grid> : null
              }
            </>
          )}
        </Grid>
        <Box textAlign="center" mt={4}>
          <Link to={`/dashboard/${tenant.name}`} className={classes.buttonLink}>
            <Button color="primary" size="large" variant="contained">
              <FormattedMessage {...messages.allClients} />
            </Button>
          </Link>
        </Box>
      </Container>
      <ErrorSnackbar error={updateUserError} />
    </>
  );
}

PaymentCalculationScreen.propTypes = {
  client: clientPropTypes.isRequired,
  coBuyers: PropTypes.arrayOf(clientPropTypes),
  initialValues: PropTypes.shape({
    comfortablePrice: PropTypes.arrayOf(PropTypes.number),
    monthlyPayment: PropTypes.number,
    downPayment: PropTypes.number,
    termOfLoan: PropTypes.number,
  }).isRequired,
  onCalculate: PropTypes.func.isRequired,
  calculations: calculationsPropTypes,
  isMortgageInsurance: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  onBuyerUpdate: PropTypes.func.isRequired,
  updateUserError: PropTypes.string,
  onUpdateUser: PropTypes.func.isRequired,
  onCoBuyerDelete: PropTypes.func.isRequired,
  onBuyerDelete: PropTypes.func.isRequired,
  onCreateComment: PropTypes.func.isRequired,
  tenant: PropTypes.shape({
    name: PropTypes.string,
    partners: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      logoUrl: PropTypes.string,
      partnerUrl: PropTypes.string,
    })),
  }).isRequired,
};

PaymentCalculationScreen.defaultProps = {
  coBuyers: [],
  isMortgageInsurance: null,
  updateUserError: null,
};
