import { defineMessages } from 'react-intl';

export default defineMessages({
  realgeniusExperience: {
    id: 'Unknown.UspSection.realgeniusExperience',
    defaultMessage: 'The RealGenius.io Experience',
  },
  realgeniusUseCase: {
    id: 'Unknown.UspSection.realgeniusUseCase',
    defaultMessage: 'RealGenius.io Use Case',
  },
  realgeniusTimeIsMoney: {
    id: 'Unknown.UspSection.realgeniusTimeIsMoney',
    defaultMessage: 'Time is Money – Buyer/Seller Demos',
  },
  benefitsToLenders: {
    id: 'Unknown.UspSection.benefitsToLenders',
    defaultMessage: 'Benefits To Lenders',
  },
  realgeniusExperienceDescription: {
    id: 'Unknown.UspSection.realgeniusExperienceDescription',
    defaultMessage: "RealGenius.io builds dynamic partnerships among lenders, title agencies, and real estate agents, offering a comprehensive approach to streamline residential listing, sales, property valuation, and financing. The Experience focus on boosting efficiency and conversion rates while enhancing the transaction experience for all parties involved.",
  },
  realgeniusUseCaseDescription: {
    id: 'Unknown.UspSection.realgeniusUseCaseDescription',
    defaultMessage: "This Video showcase’s an excellent use case demonstrating a realtor's interaction with a buyer. This highlights how the realtor assists the buyer in navigating our program, ensuring they make the best decision when purchasing a home.",
  },
  realgeniusTimeIsMoneyDescription: {
    id: 'Unknown.UspSection.realgeniusTimeIsMoneyDescription',
    defaultMessage: "This Video shows a portion of one of the RealGenius.io sales training classes. Selling in 30 second timelines. Rick walks you through a buyer and seller demo utilizing proven sales techniques. This video showcases a segment from one of the RealGenius.io sales training classes, Selling in 30 second timelines. Rick guides viewers through a buyer and seller demonstration, utilizing proven sales techniques.",
  },
  benefitsToLendersDescription: {
    id: 'Unknown.UspSection.benefitsToLendersDescription',
    defaultMessage: "RealGenius.io adopts a KISS (Keep It Simple, Stupid) approach to homeownership. Whether it’s your 1st or 50th home purchase, our brief video outlines essential concepts that significantly reduce the stress of buying a home.",
  },
});
