import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import TextField from '../../Common/TextField';
import Button from '../../Common/Button';
import Paper from '../../Common/Paper';
import Grid from '../../Common/Grid';
import Typography from '../../Common/Typography';
import Box from '../../Common/Box';
import Snackbar from '../../Common/Snackbar';
import Alert from '../../Common/Alert';
import Container from '../../Common/Container';
import Header from '../Header';
import messages from './messages';
import useStyles from './styles';

const initialValues = {
  email: '',
  password: '',
};

export default function SignInScreen({ onSignIn, error }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (error?.message) {
      setIsOpen(true);
    }
  }, [error]);

  const classes = useStyles();
  const intl = useIntl();

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = intl.formatMessage(messages.requiredEmail);
    } else if (values.email.indexOf('@') === -1) {
      errors.email = intl.formatMessage(messages.invalidEmail);
    }
    if (!values.password) {
      errors.password = intl.formatMessage(messages.requiredPassword);
    }
    return errors;
  };

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <>
      <Header />
      <Container maxWidth="xl" component="div" disableGutters>
        <Grid className={classes.root} container justifyContent="center" alignItems="center">
          <Grid item xs={8} sm={6} md={4}>
            <Paper className={classes.paper} elevation={0} square>
              <Typography variant="h5">
                <Box mb={2}>
                  <FormattedMessage {...messages.title} />
                </Box>
              </Typography>
              <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={(values, { setSubmitting }) => {
                  onSignIn(values);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <div>
                      <TextField
                        label="email"
                        variant="outlined"
                        autoComplete="off"
                        name="email"
                        fullWidth
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={!!errors.email && touched.email}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    </div>
                    <div>
                      <TextField
                        label="password"
                        variant="outlined"
                        autoComplete="off"
                        name="password"
                        fullWidth
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={!!errors.password && touched.password}
                        helperText={errors.password && touched.password && errors.password}
                      />
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage {...messages.title} />
                    </Button>
                  </form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" elevation={6} variant="filled" onClose={handleClose}>
          {error.message}
        </Alert>
      </Snackbar>
    </>
  );
}

SignInScreen.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  error: PropTypes.object,
};

SignInScreen.defaultProps = {
  error: {},
};
