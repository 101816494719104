import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'User.AddClientScreen.title',
    defaultMessage: 'Adding client',
  },
  type: {
    id: 'User.AddClientScreen.type',
    defaultMessage: 'Client type',
  },
  buyer: {
    id: 'User.AddClientScreen.buyer',
    defaultMessage: 'buyer',
  },
  seller: {
    id: 'User.AddClientScreen.seller',
    defaultMessage: 'seller',
  },
  email: {
    id: 'User.AddClientScreen.email',
    defaultMessage: 'email',
  },
  phone: {
    id: 'User.AddClientScreen.phone',
    defaultMessage: 'phone number',
  },
  firstName: {
    id: 'User.AddClientScreen.firstName',
    defaultMessage: 'first name',
  },
  lastName: {
    id: 'User.AddClientScreen.lastName',
    defaultMessage: 'last name',
  },
  requiredEmail: {
    id: 'User.AddClientScreen.requiredEmail',
    defaultMessage: 'Email is required.',
  },
  invalidEmail: {
    id: 'User.AddClientScreen.invalidEmail',
    defaultMessage: 'Invalid email.',
  },
  requiredFirstName: {
    id: 'User.AddClientScreen.requiredName',
    defaultMessage: 'First name is required.',
  },
  requiredLastName: {
    id: 'User.AddClientScreen.requiredLastName',
    defaultMessage: 'Last name is required.',
  },
  requiredPhone: {
    id: 'User.AddClientScreen.requiredPhone',
    defaultMessage: 'Phone number is required.',
  },
  save: {
    id: 'User.AddClientScreen.save',
    defaultMessage: 'Save',
  },
  addCoBuyer: {
    id: 'User.AddClientScreen.addCoBuyer',
    defaultMessage: 'add co-buyer',
  },
  removeCoBuyer: {
    id: 'User.AddClientScreen.removeCoBuyer',
    defaultMessage: 'remove co-buyer',
  },
  emailLabel: {
    id: 'User.AddClientScreen.emailLabel',
    defaultMessage: 'Email:',
  },
  name: {
    id: 'User.AddClientScreen.nameLabel',
    defaultMessage: 'Name:',
  },
  phoneNumber: {
    id: 'User.AddClientScreen.phoneNumberLabel',
    defaultMessage: 'Phone:',
  },
});
