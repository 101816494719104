import { defineMessages } from 'react-intl';

export default defineMessages({
  copyrightMessage: {
    id: 'Unknown.Footer.copyrightMessage',
    defaultMessage: `Copyright ${new Date().getFullYear()} Real Genius | All rights reserved`,
  },
  contactUs: {
    id: 'Unknown.Footer.contactUs',
    defaultMessage: 'Contact us',
  },
  termsConditions: {
    id: 'Unknown.Footer.termsConditions',
    defaultMessage: 'Terms & conditions',
  },
  privacyPolicy: {
    id: 'Unknown.Footer.privacyPolicy',
    defaultMessage: 'Privacy policy',
  },
});
