import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import TextField from '../../Common/TextField';
import PhoneFormat from './PhoneFormat';
import messages from './messages';
import { clientFields } from '../../../common/commonConstants';
import Box from '../../Common/Box';
import Grid from '../../Common/Grid';
import Typography from '../../Common/Typography';
import useStyles from './styles';

export default function ClientFields({
  onChange, onBlur, values, errors, touched, clientType, inPayment,
}) {
  const classes = useStyles();
  const intl = useIntl();

  const getError = (fieldName) => !!errors[clientType]
    && !!errors[clientType][fieldName]
    && touched[clientType]
    && touched[clientType][fieldName];

  const getHelperText = (fieldName) => errors[clientType]
    && errors[clientType][fieldName]
    && touched[clientType]
    && touched[clientType][fieldName]
    && errors[clientType][fieldName];

  return (
    <>
      {inPayment ? (
        <Box mb={3}>
          <Box mb={2}>
            <Grid container>
              <Grid item sm={4} md={2} xs={5}>
                <Typography variant="h6">
                  <FormattedMessage {...messages.name} />
                </Typography>
              </Grid>
              <Grid item sm={8} md={10} xs={7}>
                <Box display="flex" alignItems="center" height="100%" ml={3}>
                  <TextField
                    autoComplete="off"
                    name={`${clientType}.${clientFields.firstName}`}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values[clientType].firstName}
                    error={getError(clientFields.firstName)}
                    helperText={getHelperText(clientFields.firstName)}
                    className={classes.marginRight}
                    placeholder="First name"
                  />
                  <TextField
                    autoComplete="off"
                    name={`${clientType}.${clientFields.lastName}`}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values[clientType].lastName}
                    error={getError(clientFields.lastName)}
                    helperText={getHelperText(clientFields.lastName)}
                    placeholder="Last name"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Grid container>
              <Grid item sm={4} md={2} xs={5}>
                <Typography variant="h6">
                  <FormattedMessage {...messages.phoneNumber} />
                </Typography>
              </Grid>
              <Grid item sm={8} md={10} xs={7}>
                <Box display="flex" alignItems="center" height="100%" ml={3}>
                  <TextField
                    autoComplete="off"
                    name={`${clientType}.${clientFields.phone}`}
                    fullWidth
                    InputProps={{
                      inputComponent: PhoneFormat,
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values[clientType].phone}
                    error={getError(clientFields.phone)}
                    helperText={getHelperText(clientFields.phone)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Grid container>
              <Grid item sm={4} md={2} xs={5}>
                <Typography variant="h6">
                  <FormattedMessage {...messages.emailLabel} />
                </Typography>
              </Grid>
              <Grid item sm={8} md={10} xs={7}>
                <Box display="flex" alignItems="center" height="100%" ml={3}>
                  <TextField
                    autoComplete="off"
                    name={`${clientType}.${clientFields.email}`}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values[clientType].email}
                    error={getError(clientFields.email)}
                    helperText={getHelperText(clientFields.email)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <>
          <Box mb={1}>
            <TextField
              label={intl.formatMessage(messages.firstName)}
              variant="outlined"
              autoComplete="off"
              name={`${clientType}.${clientFields.firstName}`}
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              value={values[clientType].firstName}
              error={getError(clientFields.firstName)}
              helperText={getHelperText(clientFields.firstName)}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={intl.formatMessage(messages.lastName)}
              variant="outlined"
              autoComplete="off"
              name={`${clientType}.${clientFields.lastName}`}
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              value={values[clientType].lastName}
              error={getError(clientFields.lastName)}
              helperText={getHelperText(clientFields.lastName)}
            />
          </Box>
          <Box mb={1}>
            <TextField
              variant="outlined"
              autoComplete="off"
              name={`${clientType}.${clientFields.phone}`}
              fullWidth
              InputProps={{
                inputComponent: PhoneFormat,
              }}
              onChange={onChange}
              onBlur={onBlur}
              value={values[clientType].phone}
              error={getError(clientFields.phone)}
              helperText={getHelperText(clientFields.phone)}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={intl.formatMessage(messages.email)}
              variant="outlined"
              autoComplete="off"
              name={`${clientType}.${clientFields.email}`}
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              value={values[clientType].email}
              error={getError(clientFields.email)}
              helperText={getHelperText(clientFields.email)}
            />
          </Box>
        </>
      )}
    </>
  );
}

ClientFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  clientType: PropTypes.string.isRequired,
  inPayment: PropTypes.bool,
};
