import { defineMessages } from 'react-intl';

export default defineMessages({
  buyer: {
    id: 'Client.ClientCard.buyer',
    defaultMessage: 'B',
  },
  seller: {
    id: 'Client.ClientCard.seller',
    defaultMessage: 'S',
  },
  firstName: {
    id: 'Client.ClientCard.firstName',
    defaultMessage: 'First name',
  },
  name: {
    id: 'Client.ClientCard.name',
    defaultMessage: 'Name: ',
  },
  lastName: {
    id: 'Client.ClientCard.lastName',
    defaultMessage: 'Last name',
  },
  email: {
    id: 'Client.ClientCard.emailCol',
    defaultMessage: 'Email:',
  },
  phone: {
    id: 'Client.ClientCard.phoneCol',
    defaultMessage: 'Phone:',
  },
  viewDetails: {
    id: 'Client.ClientCard.veiwDetails',
    defaultMessage: 'View Details',
  },
});
