import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../../common/theme';

import App from '../App';
import Router from '../Router';
import IntlProviderContainer from '../../../containers/Common/IntlProviderContainer';
import { AuthProvider } from '../../../common/authContext';
import { TenantProvider } from '../../../common/tenantContext';
import { AnalyticsProvider } from '../../../common/analyticsContext';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function Entrypoint({ history }) {

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <TenantProvider>
          <IntlProviderContainer>
            <Router history={history}>
              <ThemeProvider theme={theme}>
                <ScrollToTopOnMount />
                <App />
              </ThemeProvider>
            </Router>
          </IntlProviderContainer>
        </TenantProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

Entrypoint.propTypes = {
  history: PropTypes.object.isRequired
};

export default Entrypoint;
