/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core';

import InputAdornment from '../../Common/InputAdornment';
import Box from '../../Common/Box';
import FormControl from '../../Common/FormControl';
import InputLabel from '../../Common/InputLabel';
import Select from '../../Common/Select';
import Grid from '../../Common/Grid';
import MenuItem from '../../Common/MenuItem';
import SubmitButton from '../../Common/SubmitButton';
import FormHelperText from '../../Common/FormHelperText';

import PercentFormat from '../PaymentFormats/PercentFormat';
import Divider from '../../Common/Divider';
import ThouthandFormat from '../PaymentFormats/ThouthandFormat';
import InputBase from '../../Common/InputBase';
import Typography from '../../Common/Typography';

import getValueAddPercent from '../../../common/getValueAddPercent';
import { TOP_PRICE_PERCENT } from '../../../common/commonConstants';

import messages from './messages';
import useStyles from './styles';
import validate from './validate';

const termOptions = [15, 30];
const percentageFunds = [3, 5, 10, 15, 20, 30, 40, 50];
const comfortablePrice = {
  min: 0,
  max: 1000000,
};

export default function PaymentCalculationForm({
  onCalculate,
  initialValues,
  isMortgageInsurance,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  const initialComfortablePrice = initialValues && initialValues.comfortablePrice;

  return (
    <Formik
      initialValues={{
        ...initialValues,
        minValue: Number(initialComfortablePrice[0]) || comfortablePrice.min,
        maxValue: Number(initialComfortablePrice[1]) || comfortablePrice.max,
      }}
      validate={validate(intl)}
      onSubmit={(values, { setSubmitting }) => {
        const valuesCopy = { ...values };
        valuesCopy.comfortablePrice = [values.minValue, values.maxValue];
        valuesCopy.percentOfFundsTowardsLoan = values.percentOfFundsTowardsLoan;
        valuesCopy.userInterestRate = values.userInterestRate / 100;
        onCalculate({ ...valuesCopy, setSubmitting });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form className={classes.form}>
          <Grid container spacing={3}>
            {/* Desire price */}
            <Grid item xs={12} sm={6} md={3} className={classes.disableMarginBottom}>
              <FormControl fullWidth>
                <InputLabel className={classes.inputLabel} shrink>
                  <Typography variant="caption">
                    <FormattedMessage {...messages.desiredPrice} />
                  </Typography>
                </InputLabel>
                <InputBase
                  value={values.minValue}
                  name="minValue"
                  onChange={({ target: { name, value } }) => {
                    setFieldValue(name, value);
                    setFieldValue('maxValue', getValueAddPercent(value, TOP_PRICE_PERCENT));
                  }}
                  inputComponent={ThouthandFormat}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  placeholder="0"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    step: 50,
                    min: comfortablePrice.min,
                    max: comfortablePrice.max,
                  }}
                  className={clsx(classes.inputBase, { [classes.inputBaseError]: errors.minPrice })}
                />
              </FormControl>
            </Grid>
            {/* Top price */}
            <Grid item xs={12} sm={6} md={3} className={classes.disableMarginBottom}>
              <FormControl fullWidth>
                <InputLabel className={classes.inputLabel} shrink>
                  <Typography variant="caption">
                    <FormattedMessage {...messages.topPrice} />
                  </Typography>
                </InputLabel>
                <InputBase
                  value={values.maxValue}
                  name="maxValue"
                  onChange={handleChange}
                  onBlur={() => {
                    const minTopPrice = getValueAddPercent(values.minValue);
                    if (values.maxValue < minTopPrice) {
                      setFieldValue('maxValue', minTopPrice);
                    }
                  }}
                  inputComponent={ThouthandFormat}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  placeholder="0"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    step: 50,
                    min: comfortablePrice.min,
                    max: comfortablePrice.max,
                  }}
                  className={clsx(classes.inputBase, { [classes.inputBaseError]: errors.maxPrice })}
                />
              </FormControl>
            </Grid>
            {/* Desire monthly payment */}
            <Grid item xs={12} sm={6} md={3} className={classes.disableMarginBottom}>
            <FormControl fullWidth error={!!errors.monthlyPayment}>
                <InputLabel className={classes.inputLabel} shrink required>
                  <Typography variant="caption">
                    <FormattedMessage {...messages.monthlyPayment} />
                  </Typography>
                </InputLabel>
                <InputBase
                  value={values.monthlyPayment}
                  name="monthlyPayment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="0"
                  autoComplete="off"
                  inputComponent={ThouthandFormat}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    step: 50,
                    min: comfortablePrice.min,
                    max: comfortablePrice.max,
                  }}
                  className={clsx(classes.inputBase, { [classes.inputBaseError]: errors.monthlyPayment })}
                />
                <FormHelperText>{errors.monthlyPayment && errors.monthlyPayment}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Funds available to close */}
            <Grid item xs={12} sm={6} md={3} className={classes.disableMarginBottom}>
              <FormControl error={!!errors.downPayment} fullWidth>
                <InputLabel className={classes.inputLabel} shrink required>
                  <Typography variant="caption">
                    <FormattedMessage {...messages.downPayment} />
                  </Typography>
                </InputLabel>
                <InputBase
                  value={values.downPayment}
                  name="downPayment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="0"
                  autoComplete="off"
                  inputComponent={ThouthandFormat}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    step: 50,
                    min: comfortablePrice.min,
                    max: comfortablePrice.max,
                  }}
                  className={clsx(classes.inputBase, { [classes.inputBaseError]: errors.downPayment })}
                />
                <FormHelperText>{errors.downPayment && errors.downPayment}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} className={classes.disableMarginBottom}>
              <FormControl
                error={!!errors.termOfLoan}
                className={classes.disableMarginBottom}
                fullWidth
              >
                <InputLabel htmlFor="select-label" className={classes.inputLabel}>
                  <Typography variant="caption">
                    <FormattedMessage {...messages.termOfLoan} />
                  </Typography>
                </InputLabel>
                <Select
                  inputProps={{
                    name: 'termOfLoan',
                    id: 'select-label',
                  }}
                  className={classes.selectInput}
                  label={intl.formatMessage(messages.termOfLoan)}
                  value={values.termOfLoan}
                  onChange={handleChange}
                >
                  {termOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.termOfLoan && touched.termOfLoan && errors.termOfLoan}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.disableMarginBottom}>
              <FormControl error={!!errors.userInterestRate} className={classes.disableMarginBottom} fullWidth>
                <InputLabel className={classes.inputLabel} shrink required>
                  <Typography variant="caption">
                    <FormattedMessage {...messages.userInterestRate} />
                  </Typography>
                </InputLabel>
                <InputBase
                  value={values.userInterestRate}
                  name="userInterestRate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="0"
                  autoComplete="off"
                  inputComponent={PercentFormat}
                  className={clsx(classes.inputBase, { [classes.inputBaseError]: errors.userInterestRate })}
                />
                <FormHelperText>{errors?.userInterestRate ?? ''}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.disableMarginBottom}>
              <FormControl
                fullWidth
                error={!!errors.percentOfFundsTowardsLoan}
              >
                <InputLabel htmlFor="select-label" className={classes.inputLabel}>
                  <Typography variant="caption">
                    <FormattedMessage {...messages.percentOfFundsTowardsLoan} />
                  </Typography>
                </InputLabel>
                <Select
                  inputProps={{
                    name: 'percentOfFundsTowardsLoan',
                    id: 'percentOfFundsTowardsLoan',
                  }}
                  className={classes.selectInput}
                  value={values.percentOfFundsTowardsLoan}
                  onChange={handleChange}
                >
                  {percentageFunds.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.percentOfFundsTowardsLoan && errors.percentOfFundsTowardsLoan}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Box>
            {isMortgageInsurance ? (
              <Box color={theme.palette.error.main} mt={1.5}>
                <Typography variant="body1">
                  <FormattedMessage {...messages.isMortgageInsurance} />
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box textAlign="center">
            <SubmitButton
              isSubmitting={isSubmitting}
              text={intl.formatMessage(messages.clickCalculate)}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}

PaymentCalculationForm.propTypes = {
  onCalculate: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    _id: PropTypes.string,
    comfortablePrice: PropTypes.arrayOf(PropTypes.number),
    monthlyPayment: PropTypes.number,
    downPayment: PropTypes.number,
    termOfLoan: PropTypes.number,
    mortgageInsurance: PropTypes.bool,
  }).isRequired,
  isMortgageInsurance: PropTypes.bool,
};

PaymentCalculationForm.defaultProps = {
  isMortgageInsurance: null,
};
