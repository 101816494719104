import React, {
  useRef, useCallback, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import ClientList from '../../components/Client/ClientList';
import { AuthContext } from '../../common/authContext';
import { Client } from '../../models/client.model';
import { getClients } from '../../services/client.service';
import { TenantContext } from '../../common/tenantContext';

const sortByMap: {[x:string]: number} = {
  oldest: 1,
  newest: -1,
};

interface ClientListProps {
  clients: Array<Client>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setClients: (...args: any[]) => any,
  statusCol: string,
  sortBy: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateCard: (...args: any[]) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteCard: (...args: any[]) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deletableCard: {[x:string]: any},
  searchText: string,
  showArchive: boolean,
  showType: {seller: boolean, buyer: boolean},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDeletableCard: (...args: any[]) => any,
}

export default function ClientListContainer({
  sortBy,
  onUpdateCard,
  onDeleteCard,
  statusCol,
  deletableCard,
  clients,
  setClients,
  searchText,
  showArchive,
  showType,
  setDeletableCard,
}: ClientListProps) {
  const { authUser } = useContext(AuthContext);
  const { _id } = authUser ?? {};
  const { tenant } = useContext(TenantContext);
  const tenantId = tenant?._id;
  const lastVisible = useRef<Client>();
  
  const filteredClients = clients ? 
    clients.filter(
      (item) => `${item.firstName} ${item.lastName}`
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) >= 0
    ) : [];

  const updateClients = useCallback(
    (documentSnapshots: Array<Client>, isReordered: boolean) => {
      lastVisible.current = documentSnapshots?.length ? documentSnapshots[documentSnapshots.length - 1] : undefined;
      setClients([...(isReordered ? [] : clients), ...documentSnapshots]);
    },
    [clients, setClients],
  );
  
  useEffect(() => {
    if (deletableCard.isConfirm) {
      setClients([...clients].filter((client) => client._id !== deletableCard.id));
      setDeletableCard({ isConfirm: false, deletableCard: null });
    }
  }, [deletableCard, clients, setClients, setDeletableCard]);

  useEffect(() => {
    const handleLoadClients = async () => {
      const sort = sortByMap[sortBy] ?? -1;
      let types = `${showType.buyer ? 'buyer' : ''}`;
      types += types ? `${showType.seller ? ',seller' : ''}` : `${showType.seller ? 'seller' : ''}`;
      const clientsRes = await getClients(_id!, tenantId!, statusCol, sort, types, showArchive);
      updateClients(clientsRes, true);
    };
    handleLoadClients();
  }, [sortBy, showArchive, showType]);

  return (
    <ClientList
      // eslint-disable-next-line max-len
      clients={filteredClients}
      onUpdateCard={onUpdateCard}
      onDeleteCard={onDeleteCard}
    />
  );
}

ClientListContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  clients: PropTypes.array.isRequired,
  setClients: PropTypes.func.isRequired,
  statusCol: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  onDeleteCard: PropTypes.func.isRequired,
  deletableCard: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  showArchive: PropTypes.bool.isRequired,
  showType: PropTypes.object.isRequired,
  setDeletableCard: PropTypes.func.isRequired,
};
