import React from 'react';
import PropTypes from 'prop-types';
import UspCard from '../UspCard';
import Grid from '../../Common/Grid';
import messages from './messages';

const uspCards = [
  {
    id: 'video#1',
    title: messages.realgeniusExperience,
    videoUrl: 'https://youtu.be/HNmBpLJ7HZ4',
    text: messages.realgeniusExperienceDescription,
  },
  {
    id: 'video#2',
    title: messages.realgeniusUseCase,
    videoUrl: 'https://youtu.be/2_WHr1NnXdU',
    text: messages.realgeniusUseCaseDescription,
  },
  {
    id: 'video#3',
    title: messages.realgeniusTimeIsMoney,
    videoUrl: 'https://youtu.be/hWfoWRrIvbs',
    text: messages.realgeniusTimeIsMoneyDescription,
  },
  {
    id: 'video#4',
    title: messages.benefitsToLenders,
    videoUrl: 'https://www.youtube.com/watch?v=sxzpyVqWuEM',
    text: messages.benefitsToLendersDescription,
  },
];

export default function UspSection() {
  return (
    <>
      {uspCards.map(({
        id, title, videoUrl, text, secondText
      }, index) => (
        <Grid key={id} item xs={12}>
          <UspCard title={title} videoUrl={videoUrl} text={text} index={index} secondText={secondText}/>
        </Grid>
      ))}
    </>
  );
}

UspSection.propTypes = {
  remoteVideoUrls: PropTypes.object,
};

UspSection.defaultProps = {
  remoteVideoUrls: {},
};
