import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0.75),
  },
  buyer: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1.85),
  },
  seller: {
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(1.85),
  },
  marginRight: {
    marginRight: theme.spacing(3),
  },
  clientContainer: {
    color: 'black',
    textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  textInput: {
    '& input': {
      padding: '3px 0 7px',
    },
  },
  longWord: {
    'overflow-wrap': 'break-word',
    'word-wrap': 'break-word',
    '-webkit-hyphens': 'auto',
    '-ms-hyphens': 'auto',
    '-moz-hyphens': 'auto',
    'word-break': 'break-all',
    hyphens: 'auto',
  },
}));

export default useStyles;
