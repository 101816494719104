import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Container from '../../Common/Container';
import Grid from '../../Common/Grid';
import Typography from '../../Common/Typography';
import Box from '../../Common/Box';
import Divider from '../../Common/Divider';
import image from './slide1.png';
import HeaderContainer from '../../../containers/User/HeaderContainer';
import Header from '../../User/Header';
import InvitationCard from '../InvitationCard';
import UspSection from '../UspSection';
import useStyles from './styles';
import messages from './messages';
import { colors } from '../../../common/theme';
import { AuthContext } from '../../../common/authContext';

export default function HomeScreen() {
  const classes = useStyles();
  const { authUser } = useContext(AuthContext);
  return (
    <>
      {authUser?._id ? <HeaderContainer /> : <Header />}
      <Container maxWidth="xl" component="div" disableGutters>
        <Grid container justifyContent="center" className={classes.root}>
          <Box display="flex" justifyContent="center" alignItems="center" pt={{ xs: 4, sm: 8 }} pb={{ xs: 5, sm: 10 }} px={{ xs: 8, sm: 17 }}>
            <InvitationCard user={authUser} />
            <img src={image} alt="background" className={classes.image} />
          </Box>
          <Box
            bgcolor={colors.primary}
            width="100%"
            display="flex"
            py={{ xs: 3, sm: 5.5 }}
            alignItems="center"
            justifyContent="center"
          >
            <Box width={{ xs: '80%', md: '50%' }} textAlign="center">
              <Typography component="span" className={classes.uniqueTextLarge}>
                <FormattedMessage {...messages.o} />
              </Typography>
              <Typography component="span" className={classes.uniqueText}>
                <FormattedMessage {...messages.unqiue} />
              </Typography>
              <Typography component="span" className={classes.uniqueTextLarge}>
                <FormattedMessage {...messages.r} />
              </Typography>
              <Typography component="span" className={classes.uniqueText}>
                <FormattedMessage {...messages.ealtors} />
              </Typography>
              <Typography component="span" className={classes.uniqueTextLarge}>
                <FormattedMessage {...messages.c} />
              </Typography>
              <Typography component="span" className={classes.uniqueText}>
                <FormattedMessage {...messages.lients} />
              </Typography>
              <Typography component="span" className={classes.uniqueTextLarge}>
                <FormattedMessage {...messages.l} />
              </Typography>
              <Typography component="span" className={classes.uniqueText}>
                <FormattedMessage {...messages.enders} />
              </Typography>
              <Typography component="span" className={classes.uniqueTextLarge}>
                <FormattedMessage {...messages.e} />
              </Typography>
              <Typography component="span" className={classes.uniqueText}>
                <FormattedMessage {...messages.state} />
              </Typography>
              <Typography component="span" className={classes.uniqueTextLarge}>
                <FormattedMessage {...messages.t} />
              </Typography>
              <Typography component="span" className={classes.uniqueText}>
                <FormattedMessage {...messages.end} />
              </Typography>
            </Box>
          </Box>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className={classes.benefits}>
              <Box textAlign="center" mt={12.5} mb={5.5}>
                <FormattedMessage {...messages.training} />
                <Box variant="h5" component="span" color={colors.primary}>&</Box>
                <FormattedMessage {...messages.benefits} />
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={5}>
              <UspSection />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
