import { defineMessages } from 'react-intl';

export default defineMessages({
  training: {
    id: 'Unknown.HomeScreen.training',
    defaultMessage: 'Training ',
  },
  benefits: {
    id: 'Unknown.HomeScreen.benefits',
    defaultMessage: ' Benefits Modules',
  },
  text2v2: {
    id: 'Unknown.InvitationCard.text2v2',
    defaultMessage: 'Our unique decisioning tools ring transparency and simplicity to the way Realtors, Clients, and Lenders interact during Real Estate Transactions.',
  },
  quote: {
    id: 'Unknown.HomeScreen.quote',
    defaultMessage: 'With "Knowledge is power", Sir Francis Bacon, 1597',
  },
  o: {
    id: 'Unknown.uniqueMessages.o',
    defaultMessage: 'O',
  },
  unqiue: {
    id: 'Unknown.uniqueMessages.unique',
    defaultMessage: 'ur unique decisioning tools ring transparency and simplicity to the way ',
  },
  r: {
    id: 'Unknown.uniqueMessages.r',
    defaultMessage: 'R',
  },
  ealtors: {
    id: 'Unknown.uniqueMessages.ealtors',
    defaultMessage: 'ealtors, ',
  },
  c: {
    id: 'Unknown.uniqueMessages.cv',
    defaultMessage: 'C',
  },
  lients: {
    id: 'Unknown.uniqueMessages.lients',
    defaultMessage: 'lients, and ',
  },
  l: {
    id: 'Unknown.uniqueMessages.l',
    defaultMessage: 'L',
  },
  enders: {
    id: 'Unknown.uniqueMessages.lenders',
    defaultMessage: 'enders interact during ',
  },
  e: {
    id: 'Unknown.uniqueMessages.e',
    defaultMessage: 'E',
  },
  t: {
    id: 'Unknown.uniqueMessages.t',
    defaultMessage: 'T',
  },
  state: {
    id: 'Unknown.uniqueMessages.state',
    defaultMessage: 'state ',
  },
  end: {
    id: 'Unknown.uniqueMessages.end',
    defaultMessage: 'ransactions.',
  },
});
