import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Paper from '../../Common/Paper';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import messages from './messages';
import useStyles from './styles';
import IconButton from '../../Common/IconButton';
import TextField from '../../Common/TextField';
import PhoneFormat from '../../User/ClientCreateScreen/PhoneFormat';
import TypeAvatar from '../../Common/TypeAvatar';
import Divider from '../../Common/Divider';
import Grid from '../../Common/Grid';
import MuiLink from '../../Common/Link';

import {
  EditBuyerSmall,
  DeleteBuyerSmall,
  DeleteSellerSmall,
  EditSellerSmall,
} from '../../../common/Icons';

export default function ClientCard({
  firstName,
  lastName,
  type,
  updatedAt,
  email,
  phone,
  _id,
  onUpdateCard,
  onDeleteCard,
  isDragging,
  status,
}) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [clientCardValues, setClientCardValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    setClientCardValues({
      _id,
      firstName,
      lastName,
      email,
      phone,
      status,
    });
    // eslint-disable-next-line
  }, []);

  const onEditBuyer = () => {
    setEditMode((editModeState) => !editModeState);
  };

  const onChangeValues = (event) => {
    const newClientCardValues = {
      ...clientCardValues,
    };
    newClientCardValues[event.target.name] = event.target.value;
    setClientCardValues(newClientCardValues);
    onUpdateCard(newClientCardValues);
  };

  const onDeleteBuyer = () => {
    onDeleteCard(_id);
  };

  const renderFields = () => (
    <Grid className={classes.clientContainer}>
      <Box mb={1}>
        <Grid container alignItems="center">
          <Box mr={1}>
            <Typography variant="h1">
              <FormattedMessage {...messages.name}/>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" height="100%" overflow="hidden">
              {editMode ? (
                <>
                  <TextField
                    value={clientCardValues.firstName}
                    name="firstName"
                    InputProps={{
                      style: { fontSize: '0.875rem' },
                    }}
                    onChange={(event) => onChangeValues(event)}
                    className={classes.marginRight}
                  />
                  <TextField
                    value={clientCardValues.lastName}
                    name="lastName"
                    InputProps={{
                      style: { fontSize: '0.875rem' },
                    }}
                    onChange={(event) => onChangeValues(event)}
                  />
                </>
              ) : (
                <Typography variant="subtitle1" className={classes.longWord}>
                  {`${clientCardValues.firstName} ${clientCardValues.lastName}`}
                </Typography>
              )}
            </Box>
        </Grid>
      </Box>
      <Box mb={1}>
        <Grid container alignItems="center">
          <Box mr={1}>
            <Typography variant="h1">
              <FormattedMessage {...messages.phone} />
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" height="100%" overflow="hidden">
              {editMode ? (
                <TextField
                  InputProps={{
                    style: { fontSize: '0.875rem' },
                    inputComponent: PhoneFormat,
                  }}
                  value={clientCardValues.phone || ''}
                  name="phone"
                  onChange={(event) => onChangeValues(event)}
                />
              ) : (
                <TextField
                  name="Phone"
                  fullWidth
                  InputProps={{
                    style: { fontSize: '0.875rem' },
                    disableUnderline: true,
                    inputComponent: PhoneFormat,
                  }}
                  value={clientCardValues.phone || ''}
                />
              )}
            </Box>
        </Grid>
      </Box>
      <Box>
        <Grid container alignItems="center">
          <Box mr={1}>
            <Typography variant="h1" component="span">
              <FormattedMessage {...messages.email} />
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" height="100%" overflow="hidden">
              {editMode ? (
                <TextField
                  value={clientCardValues.email || ''}
                  name="email"
                  InputProps={{
                    style: { fontSize: '0.875rem' },
                    className: classes.textInput,
                  }}
                  onChange={(event) => onChangeValues(event)}
                />
              ) : (
                <Typography variant="subtitle1" className={classes.longWord}>{clientCardValues.email}</Typography>
              )}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
  const renderCardHeader = () => (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <TypeAvatar type={type} inCard />
        <Box ml={1} mr={1}>
          <Typography variant="body1">
            <FormattedDate value={updatedAt} />
          </Typography>
        </Box>
        <Box>
          <IconButton className={classes.iconButton} onClick={onEditBuyer}>
            {type === 'seller' ? <EditSellerSmall /> : <EditBuyerSmall />}
          </IconButton>
          <IconButton className={classes.iconButton} onClick={onDeleteBuyer}>
            {type === 'seller' ? <DeleteSellerSmall /> : <DeleteBuyerSmall />}
          </IconButton>
        </Box>
      </Grid>
      <Box px={1.5} mt={1} mb={1.75}>
        <Divider />
      </Box>
    </>
  );

  return (
    <Paper className={classes[type]} style={{ opacity: isDragging ? 0.75 : 1 }}>
      {renderCardHeader()}
      {editMode ? (
        renderFields()
      ) : (
        <Link to={`/clients/${_id}`} className={classes.link}>
          {renderFields()}
        </Link>
      )}
      <Link to={`/clients/${_id}`} className={classes.link}>
        <Box textAlign="center" mt={2.5}>
          <MuiLink
            color={type === 'seller' ? 'secondary' : 'primary'}
            variant="subtitle2"
            component="span"
          >
            <FormattedMessage {...messages.viewDetails} />
          </MuiLink>
        </Box>
      </Link>
    </Paper>
  );
}

ClientCard.propTypes = {
  _id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  type: PropTypes.string.isRequired,
  updatedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  phone: PropTypes.string,
  email: PropTypes.string,
  onUpdateCard: PropTypes.func.isRequired,
  onDeleteCard: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

ClientCard.defaultProps = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};
