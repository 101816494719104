/* eslint-disable max-len */
import React, {
  useEffect, useState, useCallback, useRef, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import PaymentCalculationScreen from '../../components/Payment/PaymentCalculationScreen';
import { AuthContext } from '../../common/authContext';
import { createClient, deleteClient, getClient, updateClient } from '../../services/client.service';
import { useApiEffect } from '../../services/api.base.service';
import { TenantContext } from '../../common/tenantContext';
import { createCalculation, getCalculations } from '../../services/calculation.service';
import { AnalyticsContext } from '../../common/analyticsContext';
import { AnalyticEventNames } from '../../common/analyticEventNames.enum';

const calculatingFields = [
  'percentLoanToValue',
  'homePrice',
  'downPaymentDashboard',
  'loanAmount',
  'principalInterest',
  'taxesInsurance',
  'mortgageInsurance',
  'totalEstimatedPayment',
  'desiredPayment',
  'betweenWithDesired',
  'loanOrigFee',
  'administrationFee',
  'appraisalTitlePolicy',
  'otherFees',
  'estimateClosingCosts',
  'prepaidInterest15days',
  'prepaidPropIns14days',
  'prepaidTaxes2mo',
  'prepaidMortIns1mo',
  'estPrepaids',
  'fundsRequiredToCloseABC',
  'fundsAvailableToClose',
  'betterOrWorseFundsToClose',
  'requiredIncome',
];

const calculatingFieldsSeller = [
  'homePrice',
  'totalLoanBalance',
  'netFromPriceLessLoan',
  'estPreClosingCosts',
  'sellerNetBeforeClosing',
  'estimatedSellerPaidClosingCosts',
  'totalSellerPaidClosingCosts',
  'propertyTaxes',
  'otherItemsTotal',
  'commissionOwed',
  'warrantyDeed',
  'escrowFee',
  'recording',
  'homeWarranty',
  'ownersTitlePolicy',
  'lienRelease',
  'allowanceOtherTotal',
  'estimatedNetProceeds',
];

export default function PaymentCalculationScreenContainer({ match }) {
  const [coBuyers, setCoBuyers] = useState([]);
  const { authUser } = useContext(AuthContext);
  const { _id } = authUser ?? {};
  const { tenant } = useContext(TenantContext);
  const tenantId = tenant?._id;
  const {
    params: { id: clientId },
  } = match;
  const { trackEvent } = useContext(AnalyticsContext);
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const {
    isLoading: loadingClient,
    data: client,
    execute: clientRequest,
  } = useApiEffect();
  
  useEffect(() => {
    clientRequest(() => getClient(clientId));
  }, []);

  const formRef = useRef();

  const [updateUserError, setUpdateError] = useState(null);

  const [initialValues, setInitialValues] = useState({
    comfortablePrice: [0, 1000000],
    monthlyPayment: undefined,
    downPayment: undefined,
    termOfLoan: 15,
    percentOfFundsTowardsLoan: 3,
    userInterestRate: 3.375,
  });

  const [initialValuesSeller, setInitialValuesSeller] = useState({
    listingNumber: 0,
    min: 0,
    max: 0,
    propertyAdress: '',
    city: '',
    state: '',
    zip: '',
    agentCommission: 6,
    estimateBalanceOne: 0,
    estimateBalanceTwo: 0,
    otherItemOne: 0,
    otherItemTwo: 0,
    allowanceOtherOne: 0,
    allowanceOtherTwo: 0,
    allowanceOtherThree: 0,
    closeDate: null,
    listDate: null,
    offerDate: null,
  });

  const getCoBuyers = useCallback(() => {
    if (!client?.coBuyers?.length) {
      setCoBuyers([]);
      return;
    }
    const coBuyersRequest = async () => {
      const coBuyer = await getClient(client.coBuyers[0]);
      if (coBuyer?._id) {
        setCoBuyers([
          {...coBuyer}
        ])
      }
    };
    coBuyersRequest();
  }, [client?._id]);

  useEffect(() => {
    getCoBuyers();
  }, [getCoBuyers]);

  const onUpdateUser = async (updatebleBuyer) => {
    await updateClient(updatebleBuyer._id, updatebleBuyer);
  };

  const [calculations, setCalculations] = useState(null);
  const [mortgageInsurance, setMortgageInsurance] = useState(false);
  
  useEffect(() => {
    if (!client?._id) return;
    const calculationRequest = async () => {
      const calculationRes = await getCalculations(tenantId, client._id);
      const rawCalculations = calculationRes?.[0] ?? {};
      const calculationFormatted = formatCalculations(rawCalculations);
      setCalculations({_id: rawCalculations?._id, ...calculationFormatted});
      if (client?.type === 'seller') {
        setInitialValuesSeller({...initialValuesSeller});
      } else {
        setInitialValues({_id: rawCalculations?._id, ...initialValues});
      }
    };
    calculationRequest();
  }, [client?._id]);

  const formatCalculations = (rawCalculations) => {
    let lCalculations = {};
      if (client?.type === 'buyer') {
        lCalculations = Object.keys(rawCalculations).length > 0
          ? calculatingFields.reduce((acc, key) => {
            const value = rawCalculations[key] || (calculations?.[key]);
            return value ? { ...acc, [key]: value } : acc;
          }, {})
          : {};
      } else {
        lCalculations = Object.keys(rawCalculations).length > 0
          ? calculatingFieldsSeller.reduce((acc, key) => {
            let value = rawCalculations[key] || (calculations?.[key]);
            if (!value) {
              value = ' ';
            }
            // Calculating missing value -> estPreClosingCosts
            if (value === ' ' && key === 'estPreClosingCosts') {
              value = [
                (rawCalculations['propertyTaxes']?.[0] ?? 0) + (rawCalculations['otherItemsTotal']?.[0] ?? 0) + (rawCalculations['commissionOwed']?.[0] ?? 0),
                (rawCalculations['propertyTaxes']?.[1] ?? 0) + (rawCalculations['otherItemsTotal']?.[1] ?? 0) + (rawCalculations['commissionOwed']?.[1] ?? 0)  
              ]
            }
            return value ? { ...acc, [key]: value } : acc;
          }, {})
          : {};
      }
      if (client?.type === 'buyer') {
        Object.keys(initialValues).forEach((key) => {
          const value = rawCalculations[key];
          if (typeof value !== 'undefined') {
            initialValues[key] = value;
          }
          if (key === 'userInterestRate' && value) {
            initialValues[key] = Number((value * 100).toFixed(3));
          }
        });
      } else if (client?.type === 'seller') {
        Object.keys(initialValuesSeller).forEach((key) => {
          const value = rawCalculations[key];
          if (typeof value !== 'undefined') {
            initialValuesSeller[key] = value;
          }
        });
        initialValuesSeller.min = rawCalculations.homePrice ? rawCalculations.homePrice[0] : initialValues.min;
        initialValuesSeller.max = rawCalculations.homePrice ? rawCalculations.homePrice[1] : initialValues.max;
      }
      setMortgageInsurance((rawCalculations?.mortgageInsurance && rawCalculations?.mortgageInsurance?.[0] > 0.8) ?? false);
      return lCalculations;
  }

  const handleCalculate = async (allData) => {
    const {
      comfortablePrice,
      monthlyPayment,
      downPayment,
      termOfLoan,
      setSubmitting,
      percentOfFundsTowardsLoan,
      userInterestRate,
      handleCalculateButton
    } = allData;
    setLoading(true);
    // to skip the effect above
    formRef.current = null;
    const data = {
      comfortablePrice,
      monthlyPayment: Number(monthlyPayment),
      downPayment: Number(downPayment),
      termOfLoan,
      percentOfFundsTowardsLoan,
      userInterestRate,
    };
    const newCalculation = await createCalculation({
      tenantId,
      clientId,
      ...data
    });
    trackEvent(AnalyticEventNames.RealtorInteraction, {realtorId: authUser?._id, clientId, tenantId, clientType: 'buyer', action: 'Calculate'});
    const calculationFormatted = formatCalculations(newCalculation);
    setCalculations({_id: newCalculation?._id, ...calculationFormatted});
    setLoading(false);
    formRef.current = { setSubmitting };
    formRef.current.setSubmitting(false);
    if (handleCalculateButton) { handleCalculateButton() }
  };

  const handleCalculateSeller = async (allData) => {
    const {
      listingNumber,
      listDate,
      offerDate,
      closeDate,
      comfortablePrice,
      propertyAdress,
      city,
      state,
      zip,
      agentCommission,
      estimateBalanceOne,
      estimateBalanceTwo,
      otherItemOne,
      otherItemTwo,
      allowanceOtherOne,
      allowanceOtherTwo,
      allowanceOtherThree,
      setSubmitting,
      handleCalculateButton,
    } = allData;
    setLoading(true);
    // to skip the effect above
    formRef.current = null;
    const data = {
      listingNumber,
      listDate,
      offerDate,
      closeDate,
      comfortablePrice,
      propertyAdress,
      city,
      state,
      zip,
      agentCommission,
      estimateBalanceOne,
      estimateBalanceTwo,
      otherItemOne,
      otherItemTwo,
      allowanceOtherOne: allowanceOtherOne || 0,
      allowanceOtherTwo: allowanceOtherTwo || 0,
      allowanceOtherThree: allowanceOtherThree || 0,
    };
    const newCalculation = await createCalculation({
      tenantId,
      clientId,
      ...data
    });
    trackEvent(AnalyticEventNames.RealtorInteraction, {realtorId: authUser?._id, clientId, tenantId, clientType: 'seller', action: 'Calculate'});
    const calculationFormatted = formatCalculations(newCalculation);
    setCalculations({_id: newCalculation?._id, ...calculationFormatted});
    setLoading(false);
    formRef.current = { setSubmitting };
    formRef.current.setSubmitting(false);
    if (handleCalculateButton) { handleCalculateButton(); }
  };

  const createCoBuyer = async ({
    firstName,
    lastName,
    phone,
    email,
    type = 'buyer',
    isPrincipal,
  }) => {
    setUpdateError(null);
    const data = {
      firstName,
      lastName,
      phone,
      email,
      type,
      realtorId: _id,
      tenantId,
      createdAt: new Date(),
      isPrincipal,
    };
    const coBuyerRes = await createClient(data);
    setCoBuyers([{...coBuyerRes}]);
    return coBuyerRes._id;
  };

  const onBuyerUpdate = async ({ coBuyer, setSubmitting, closeForm }) => {
    try {
      const coBuyerId = await createCoBuyer({
        ...coBuyer,
        isPrincipal: false,
      });
      let buyerCoBuyers = [];
      if (client?.coBuyers?.length) {
        buyerCoBuyers = [...(client.coBuyers)];
        buyerCoBuyers.push(coBuyerId);
      } else {
        buyerCoBuyers = [coBuyerId];
      }
      await updateClient(clientId, {coBuyers: buyerCoBuyers});
      closeForm();
    } catch (err) {
      setUpdateError(err.message);
      setSubmitting(false);
    }
  };

  const onCoBuyerDelete = async (id) => {
    await deleteClient(id);
    const newCobuyers = client?.coBuyers?.filter((coBuyerId) => coBuyerId !== id) ?? [];
    await updateClient(clientId, {coBuyers: newCobuyers});
  };

  const onBuyerDelete = async () => {
    await Promise.all(coBuyers?.map(coBuyer => {
      return deleteClient(coBuyer._id);
    }));
    if (client?._id) {
      await deleteClient(client._id);
    }
    history.push('/welcome');
  };

  const onCommentCreate = async (updatebleBuyer) => {
    await updateClient(clientId, {
      comments: updatebleBuyer?.comments ?? []
    });
  };

  if (loadingClient || !client?._id) return <></>;

  return (
    <PaymentCalculationScreen
      onCreateComment={onCommentCreate}
      initialValues={client.type === 'seller' ? initialValuesSeller : initialValues}
      onCalculate={client.type === 'seller' ? handleCalculateSeller : handleCalculate}
      client={client}
      coBuyers={coBuyers}
      calculations={calculations}
      isMortgageInsurance={mortgageInsurance}
      isLoading={isLoading}
      onUpdateUser={onUpdateUser}
      onBuyerUpdate={onBuyerUpdate}
      updateUserError={updateUserError}
      onCoBuyerDelete={onCoBuyerDelete}
      onBuyerDelete={onBuyerDelete}
      tenant={tenant}
    />
  );
}

PaymentCalculationScreenContainer.propTypes = {
  match: PropTypes.object.isRequired,
};
