import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  invitationSection: {
    position: 'relative',
    background: theme.palette.secondary.invitationWhite,
  },
  root: {
    position: 'relative',
  },
  image: {
    width: '70%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  uniqueText: {
    color: 'white',
    letterSpacing: '1px',
    lineHeight: '35px',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.up('xs')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
  uniqueTextLarge: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: 'white',
    fontSize: '24px',
    lineHeight: '35px',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
  },
  benefits: {
    fontSize: '30px',
    fontWeight: 600,
  },
}));

export default useStyles;
