import React from 'react';
import { useField } from 'formik';
import RadioGroup from '../RadioGroup';

export default function FormikRadioGroup(props) {
  const [field] = useField(props);

  return (
    <RadioGroup
      {...field}
      {...props}
    />
  );
}
