import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(3),
  },
  root: {
    marginTop: 50,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
