import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Toolbar from '../../Common/Toolbar';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import Divider from '../../Common/Divider';
import logo from '../../../assets/newLogo.svg';
import AppBar from '../../Common/AppBar';
import Button from '../../Common/Button';
import useStyles from './styles';
import messages from './messages';


export default function Header({ user, onLogout, tenant }) {
  const classes = useStyles();
  const history = useHistory();
  const handleLogIn = () => history.push('/sign-in');
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:767px)');
  const location = useLocation();
  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar disableGutters={!!matches}>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" pb={2.5} px={2}>
          <Link to="/">
            <Box display="flex" alignItems="center">
              <img src={logo} alt="logo" width={65} />
            </Box>
          </Link>
          {user?._id ? (
            <Box display="flex" alignItems="center">
              {
                tenant?.name && !matches && location.pathname.match(/(dashboard|clients)/gi)
                  ? <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      color="text.primary"
                      mx={{ xs: 1, sm: 0 }}
                    >
                      <Typography variant="subtitle2">
                        <Box color="text.secondary">{tenant.name}</Box>
                      </Typography>
                    </Box>
                    { matches ? null : <Divider orientation="vertical" variant="middle" flexItem component="div" />}
                  </>
                  : null
              }
              <Box
                bgcolor={theme.palette.secondary.darkGray}
                height="40px"
                width="40px"
                display="flex"
                alignItems="center"
                color="white"
                borderRadius="50%"
                mr={{ xs: 0, sm: 1.5 }}
                justifyContent="center"
              >
                {`${user?.firstName?.[0] ?? ''}${user?.lastName?.[0] ?? ''}`}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-end"
                color="text.primary"
                mx={{ xs: 1, sm: 0 }}
              >
                <Typography variant="subtitle1">
                  <Box fontWeight={500}>{`${user.firstName || ''} ${user.lastName || ''}`}</Box>
                </Typography>
                <Typography variant="subtitle2">
                  <Box color="text.secondary">{user.companyName}</Box>
                </Typography>
              </Box>
              { matches ? null : <Divider orientation="vertical" variant="middle" flexItem component="div" />}
              <Button color="primary" onClick={onLogout}>
                <FormattedMessage {...messages.logout} />
              </Button>
            </Box>
          ) : (
            <Button color="primary" onClick={handleLogIn}>
              <FormattedMessage {...messages.login} />
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onLogout: PropTypes.func,
  user: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
  }),
  tenant: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
};

Header.defaultProps = {
  onLogout: null,
  user: null,
};
