import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AccessToken, Account } from '../models';
import { getAccount } from '../services/account.service';

export const AuthContext = React.createContext<{authUser: Account | null, accountLoaded: boolean, setAuthUser: React.Dispatch<React.SetStateAction<Account | null>>}>({
  authUser: null,
  accountLoaded: false,
  setAuthUser: () => {}
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthProvider = ({ children }: {children: any}) => {
  const [authUser, setAuthUser] = useState<Account | null>(null);
  const [accountLoaded, setAccountLoaded] = useState<boolean>(false);
  const [lastAccountId, setLastAccountId] = useState<string>('');
  const value = {authUser, accountLoaded, setAuthUser};

  useEffect(() => {

    const fetchAccount = async () => {
      try {
        if (lastAccountId === authUser?._id) {
          setAccountLoaded(true);
          return;
        }
        const accessToken: AccessToken = JSON.parse(
          localStorage.getItem('realgenius:token') ?? '{}'
        );
        if (accessToken?.id) {
          const account = await getAccount(accessToken.id);
          setLastAccountId(account._id);
          setAuthUser(account);
          setAccountLoaded(true);
        } else {
          setAccountLoaded(true);
        }
      } catch (err) {
        if (err === 'Invalid token') {
          localStorage.removeItem('realgenius:token');
          setAccountLoaded(true);
        }
      }
    }

    if (!authUser || lastAccountId !== authUser._id) {
      fetchAccount()
        .catch(console.error);
    }
  }, [authUser?._id]);


  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
