import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      background: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    backgroundColor: 'white',
    width: 18,
    height: 18,
    border: `1.5px solid ${theme.palette.secondary.lightGray}`,
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.secondary.lightGray,
    },
  },
  checkedIcon: {
    '&:before': {
      backgroundColor: colors.primary,
      display: 'block',
      borderRadius: 3,
      border: '1.5px solid white',
      boxSizing: 'border-box',
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath"
        + " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 "
        + "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
}));

export default useStyles;
