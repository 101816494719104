import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'User.SignUpScreen.title',
    defaultMessage: 'Create account',
  },
  text: {
    id: 'User.SignUpScreen.text',
    defaultMessage: 'Welcome to the Real Genius Realtor Page!',
  },
  benefits: {
    id: 'User.SignUpScreen.benefits',
    defaultMessage: 'Are you Ready to Close More, Get to Yes quicker, Have visibility of your clients from 1st call to title company?',
  },
  name: {
    id: 'User.SignUpScreen.name',
    defaultMessage: 'name',
  },
  email: {
    id: 'User.SignUpScreen.email',
    defaultMessage: 'email',
  },
  password: {
    id: 'User.SignUpScreen.password',
    defaultMessage: 'password',
  },
  company: {
    id: 'User.SignUpScreen.company',
    defaultMessage: 'company',
  },
  tenant: {
    id: 'User.SignUpScreen.tenant',
    defaultMessage: 'tenant',
  },
  requiredName: {
    id: 'User.SignUpScreen.requiredName',
    defaultMessage: 'Name is required.',
  },
  requiredEmail: {
    id: 'User.SignUpScreen.requiredEmail',
    defaultMessage: 'Email is required.',
  },
  requiredPassword: {
    id: 'User.SignUpScreen.requiredPassword',
    defaultMessage: 'Password is required.',
  },
  requiredCompany: {
    id: 'User.SignUpScreen.requiredCompany',
    defaultMessage: 'Company is required.',
  },
  invalidEmail: {
    id: 'User.SignUpScreen.incorrectEmail',
    defaultMessage: 'The email is invalid',
  },
});
