import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { FormattedMessage } from 'react-intl';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import useStyles from './styles';
import Grid from '../../Common/Grid';
import { colors } from '../../../common/theme';

export default function UspCard({
  title, videoUrl, text, secondText, index,
}) {
  const classes = useStyles();
  const isOdd = index % 2 === 1;
  return (
    <Grid container spacing={6} direction={isOdd ? 'row-reverse' : 'row'} className={classes.root}>
      <Grid item xs={12} sm={6}>
        <div className={classes.playerWrapper}>
          <ReactPlayer width="100%" height="100%" className={classes.reactPlayer} url={videoUrl} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.container}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          position="relative"
          ml={isOdd ? { lg: 17.5 } : 0}
          mr={!isOdd ? { lg: 17.5 } : 0}
          mt={{ xs: 2, sm: 0 }}
        >
          <Box position="relative">
            <Box
              position="absolute"
              top="-75px"
              zIndex={-1}
              fontSize="100px"
              color={colors.primaryLight}
              lineHeight="122px"
            >
              {`${index + 1}`.padStart(2, '0')}
            </Box>
            <Box position="relative" top="0px" zIndex={1} ml={3}>
              <Typography variant="h2">
                <FormattedMessage {...title} />
              </Typography>
            </Box>
          </Box>
          {!!text && (
            <Box mt={2}>
              <Typography component="p">
                <FormattedMessage {...text} />
              </Typography>
            </Box>
          )}
          {secondText && (
            <Box mt={1}>
              <Typography component="p">
                <FormattedMessage {...secondText} />
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

UspCard.propTypes = {
  title: PropTypes.object.isRequired,
  videoUrl: PropTypes.string.isRequired,
  text: PropTypes.object,
  index: PropTypes.number.isRequired,
  secondText: PropTypes.object
};

UspCard.defaultProps = {
  text: {}
};
