import { AnalyticEventNames } from '../common/analyticEventNames.enum';
import { getToken } from './api.base.service';

export const saveEvent = async (eventName: AnalyticEventNames, properties: object): Promise<void> => {
    const data = {
        name: eventName,
        properties
    }
  await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}analytic-event`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': getToken()
      },
      body: JSON.stringify(data)
    }
  );
  return;
};
