import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: '90px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
