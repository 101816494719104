import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Box from '../Box';
import CircularProgress from '../CircularProgress';
import HomeScreen from '../../../containers/Unknown/HomeScreenContainer';
import SignUpScreen from '../../../containers/User/SignUpScreenContainer';
import SignInScreen from '../../../containers/User/SignInScreenContainer';
import DashboardScreen from '../../../containers/User/DashboardScreenContainer';
import WelcomeScreen from '../../User/WelcomeScreen';
import ClientCreateScreen from '../../../containers/User/ClientCreateScreenContainer';
import PaymentCalculationScreen from '../../../containers/Payment/PaymentCalculationScreenContainer';
import withRouteProtection from '../withRouteProtection';
import useStyles from './styles';
import Footer from '../../Unknown/Footer';
import PrivacyPolicy from '../../Unknown/PrivacyPolicy';

function Progress() {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.progressContainer}
    >
      <CircularProgress />
    </Box>
  );
}

// `component`s should have same values to reuse them in `Switch`
// details are here: https://github.com/ReactTraining/react-router/issues/4578#issuecomment-282081567

export default function Routes() {
  const classes = useStyles();
  return (
    <Suspense fallback={<Progress />}>
      <Box className={classes.mainWrapper}>
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/sign-up/:tenantSlug" exact component={SignUpScreen} />
          <Route path="/sign-in" exact component={SignInScreen} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/dashboard/:tenantName" exact component={withRouteProtection(DashboardScreen)} />
          <Route path="/welcome" exact component={withRouteProtection(WelcomeScreen)} />
          <Route path="/clients/create" exact component={withRouteProtection(ClientCreateScreen)} />
          <Route path="/clients/:id" exact component={withRouteProtection(PaymentCalculationScreen)} />
        </Switch>
        <Route path="/" component={Footer} />
      </Box>
    </Suspense>
  );
}
