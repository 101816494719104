import messages from './messages';

const validate = (intl) => (values) => {
  const errors = {};

  if (!values.min) {
    errors.min = intl.formatMessage(messages.requiredListValue);
  }
  if (!values.max) {
    errors.max = intl.formatMessage(messages.requiredOfferValue);
  }
  if (!values.agentCommission) {
    errors.agentCommission = intl.formatMessage(messages.requiredAgentCmmission);
  }
  if (!values.estimateBalanceOne && values.estimateBalanceOne !== 0) {
    errors.estimateBalanceOne = intl.formatMessage(messages.requiredEstimateBalance);
  }
  // if (!values.estimateBalanceTwo && values.estimateBalanceTwo !== 0) {
  //   errors.estimateBalanceTwo = intl.formatMessage(messages.requiredEstimateBalance);
  // }
  // if (!values.otherItemOne && values.otherItemOne !== 0) {
  //   errors.otherItemOne = intl.formatMessage(messages.requiredEstimateBalance);
  // }
  // if (!values.otherItemTwo && values.otherItemTwo !== 0) {
  //   errors.otherItemTwo = intl.formatMessage(messages.requiredOtherItem);
  // }

  return errors;
};

export default validate;
