import './bootstrap';
import React from 'react';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import Entrypoint from './components/Common/Entrypoint';

const history = createBrowserHistory();

ReactDOM.render(
  <Entrypoint history={history} />,
  document.getElementById('root'),
);
