import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '../../Common/Grid';
import Container from '../../Common/Container';
import Paper from '../../Common/Paper';
import Typography from '../../Common/Typography';
import Box from '../../Common/Box';
import Alert from '../../Common/Alert';
import Snackbar from '../../Common/Snackbar';
import useStyles from './styles';
import messages from './messages';
import CreateUserForm from '../../Common/CreateUserForm';
import Header from '../../../containers/User/HeaderContainer';

const initialValues = {
  type: 'buyer',
  buyer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  coBuyer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
};

export default function ClientCreateScreen({ onCreate, error }) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [showCoBuyerForm, setShowCoBuyerForm] = useState(false);
  useEffect(() => {
    if (error) {
      setIsOpen(true);
    }
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  const toggleCoBuyerForm = () => setShowCoBuyerForm(!showCoBuyerForm);

  return (
    <>
      <Header />
      <Container maxWidth="xl" component="div" disableGutters>
        <Grid className={classes.root} container justifyContent="center" alignItems="center">
          <Grid item xs={8} sm={6} md={4}>
            <Paper className={classes.paper} elevation={0} square>
              <Typography variant="h5">
                <Box mb={4}>
                  <FormattedMessage {...messages.title} />
                </Box>
              </Typography>
              <CreateUserForm
                initialValues={initialValues}
                onSubmitCallback={onCreate}
                typeCheckbox
                showCoBuyerForm={showCoBuyerForm}
                toggleCoBuyerForm={toggleCoBuyerForm}
                isCoBuyerAllowed
              />
            </Paper>
            <Box mb={5} />
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" elevation={6} variant="filled" onClose={handleClose}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

ClientCreateScreen.propTypes = {
  onCreate: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ClientCreateScreen.defaultProps = {
  error: null,
};
