import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    backgroundColor: '#c9c9c9 !important'
  },
  partnerImage: {
    height: '50px',
    marginRight: theme.spacing(2),
  },
  partnerItem: {
    cursor: 'pointer',
    width: 'fit-content'
  }
}));

export default useStyles;
