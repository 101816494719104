import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: `${theme.spacing(1.5)}px 0px`,
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: `${theme.spacing(2)}px 0px`,
    },
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  footer: {
    zIndex: 50,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    display: 'block',
  },
  dashboardFooter: {
    minWidth: '768px',
  },
}));
export default useStyles;
