import React from 'react';
import Routes from '../Routes';
import CssBaseline from '../CssBaseline';

// an abstraction for having some components on all the screens
export default function App() {
  return (
    <>
      <Routes />
      <CssBaseline />
    </>
  );
}
