import { defineMessages } from 'react-intl';

export default defineMessages({
  welcomeTo: {
    id: 'Unknown.InvitationCard.welcomeTo',
    defaultMessage: 'Welcome to',
  },
  brand: {
    id: 'Unknown.InvitationCard.brand',
    defaultMessage: 'REAL Genius',
  },
  text1: {
    id: 'Unknown.InvitationCard.text1',
    defaultMessage: 'Real Genius mission is to make Realtors successful with every seller, every buyer, always.',
  },
  login: {
    id: 'Unknown.InvitationCard.login',
    defaultMessage: 'Log in',
  },
  welcome: {
    id: 'Unknown.InvitationCard.welcome',
    defaultMessage: 'Welcome',
  },
});
