import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'User.WelcomeScreen.title',
    defaultMessage: 'Let\'s do magic 🙌',
  },
  viewClients: {
    id: 'User.WelcomeScreen.viewClients',
    defaultMessage: 'View clients',
  },
  addClient: {
    id: 'User.WelcomeScreen.addClient',
    defaultMessage: 'Add new client',
  },
});
