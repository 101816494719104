export enum PartnerType {
  Lender = 'lender',
  TitleCompany = 'titleCompany',
  Valuation = 'valuation'
}

export interface Partner {
  _id: string;
  name: string;
  type: PartnerType;
  logoUrl?: string;
  partnerUrl: string;
}
