import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import SignUpScreen from '../../components/User/SignUpScreen/SignUpScreen';
import { AuthContext } from '../../common/authContext';
import { getAccount, signup } from '../../services/account.service';
import { Account } from '../../models';
import { getTenantPublic } from '../../services/tenant.service';
import { Alert, Skeleton } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function SignUpScreenContainer({ history }: {history: any}) {
  const [error, setError] = useState<{message?: string}>({});
  const [tenantName, setTenantName] = useState('');
  const [tenantId, setTenantId] = useState('');
  const { tenantSlug } = useParams<{tenantSlug: string}>();
  const { authUser, setAuthUser } = useContext(AuthContext);

  useEffect(() => {
    if (!tenantSlug) return;
    const tenantRequest = async () => {
      try {
        const tenant = await getTenantPublic(tenantSlug);
        setTenantName(tenant.name ?? '');
        setTenantId(tenant._id);
      } catch (err) {
        console.log('Error', err);
        setError({message: 'Error getting tenant information'});
      }
    };
    tenantRequest();
  }, [tenantSlug]);

  const handleSignUp = useCallback(async ({
    email, password, name, company
  }: {email: string, password: string, name: string, company: string, tenant: string}) => {
    const [firstName, lastName = ''] = name.split(' ');
    const account: Partial<Account> = {
      firstName,
      lastName,
      companyName: company,
      email,
      password,
      tenants: [tenantId]
    }
    try {
      const token = await signup(account);
      const accountCreated = await getAccount(token.id);
      setAuthUser(accountCreated);
      history.push('/welcome');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError({message: err?.message ?? err});
    }
  }, [history, tenantId]);

  if (authUser?._id) {
    return <Redirect to="/welcome" />;
  }

  if (!tenantName && !error?.message) {
    return <Skeleton/>;
  }

  if (error?.message) {
    return <Snackbar
        open={true}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" elevation={6} variant="filled">
          {error.message}
        </Alert>
      </Snackbar>;
  }

  return (
    <SignUpScreen onSignUp={handleSignUp} error={error} tenantName={tenantName} />
  );
}

SignUpScreenContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
