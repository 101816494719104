import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Box from '../../Common/Box';
import MuiLink from '../../Common/Link';
import messages from './messages';
import useStyles from './styles';
import SideBar from '../SocialMedia';
import Divider from '../../Common/Divider';
import { LogoFooter } from '../../../common/Icons';
import Grid from '../../Common/Grid';

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  return (
    <Box bgcolor={theme.palette.common.white} className={clsx(classes.footer, { [classes.dashboardFooter]: location.pathname.includes('/dashboard') })} px={5} py={1.5}>
      <Grid
        container
        alignItems="center"
        className={classes.container}
      >
        <Grid item md={3}>
          <Box display="flex" alignItems="center" width={120} mb={{ sm: 2, md: 0 }} mt={{ sm: 1, md: 0 }}>
            <LogoFooter />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box display="flex" justifyContent="center" pb={1}>
            <Box mr={2}>
              <Link to="/privacy-policy">
                <MuiLink component="span">
                  <FormattedMessage {...messages.privacyPolicy} />
                </MuiLink>
              </Link>
            </Box>
            <Box>
              <MuiLink href="mailto:realgeniusinfo@gmail.com">
                <FormattedMessage {...messages.contactUs} />
              </MuiLink>
            </Box>
          </Box>
          <Divider />
          <Box px={1} pt={1.5} textAlign="center">
            <FormattedMessage {...messages.copyrightMessage} values={{year: new Date().getFullYear()}} />
          </Box>
        </Grid>
        <Grid item md={3}>
          <SideBar />
        </Grid>
      </Grid>
    </Box>
  );
}
