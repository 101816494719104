import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      width: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: theme.spacing(12),
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  textContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  btn: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default useStyles;
