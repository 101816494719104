import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  addCobuyerContainer: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  statusContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  buttonLink: {
    textDecoration: 'none',
  },
  container: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(8),
    position: 'relative',
  },
  paper: {
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1.5),
    },
  },
  type: {
    textAlign: 'left',
    fontWeight: 600,
  },
  text: {
    textAlign: 'left',
  },
  form: {
    width: '100%',
  },
  phoneText: {
    fontSize: theme.typography.body2.fontSize,
  },
  editIconButton: {
    padding: '5px',
  },
  paggindBottomType: {
    textAlign: 'left',
    fontWeight: 600,
    paddingBottom: '10px',
  },
  paggindBottom: {
    paddingBottom: '10px',
  },
  deleteIconButton: {
    padding: '5px',
  },
  longWord: {
    'overflow-wrap': 'break-word',
    'word-wrap': 'break-word',
    '-webkit-hyphens': 'auto',
    '-ms-hyphens': 'auto',
    '-moz-hyphens': 'auto',
    'word-break': 'break-all',
    hyphens: 'auto',
  },
  textAreaP: {
    '& .MuiInputBase-multiline': {
      padding: 0,
      height: '52px',
      '& .MuiInputBase-inputMultiline': {
        height: '52px !important',
      },
    },
  },
  commentsContainer: {
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(2.25),
    maxHeight: '175px',
    overflowY: 'auto',
  },
  commentCard: {
    paddingBottom: theme.spacing(0.5),
  },
  archiveCheckBox: {
    '&.MuiFormControlLabel-labelPlacementStart': {
      [theme.breakpoints.down('lg')]: {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(0),
      },
    },
  },
  sortValue: {
    fontSize: '12px',
    lineHeight: 1.5,
    fontWeight: 400,
    opacity: 1,
  },
}));

export default useStyles;
