import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../common/authContext';
import Header from '../../components/User/Header';
import { TenantContext } from '../../common/tenantContext';

export default function HeaderContainer() {
  // TODO: Remove auth once the migration is completed
  const history = useHistory();
  const { authUser, setAuthUser } = useContext(AuthContext);
  const { tenant } = useContext(TenantContext);

  const handleLogout = () => {
    setAuthUser(null);
    localStorage.removeItem('realgenius:token');
    history.push('/');
  };

  return (
    <Header onLogout={handleLogout} user={authUser ?? null} tenant={tenant}/>
  );
}
