/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import Big from 'big.js';

import Box from '../../Common/Box';
import Paper from '../../Common/Paper';
import Table from '../../Common/Table';
import Button from '../../Common/Button';
import Collapse from '../../Common/Collapse';
import Skeleton from '../../Common/Skeleton';
import TableRow from '../../Common/TableRow';
import TableBody from '../../Common/TableBody';
import TableCell from '../../Common/TableCell';
import TableHead from '../../Common/TableHead';
import IconButton from '../../Common/IconButton';
import Typography from '../../Common/Typography';
import TableContainer from '../../Common/TableContainer';
import KeyboardArrowUpIcon from '../../Common/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from '../../Common/KeyboardArrowDownIcon';

import { DEFAULT_CURRENCY } from '../../../common/commonConstants';

import calculationsPropTypes from '../calculationsPropTypes';

import sortCalculationEntries from './sortCalculationEntries';
import useStyles from './styles';
import messages from './messages';

const feeFields = ['loanOrigFee', 'administrationFee', 'appraisalTitlePolicy', 'otherFees'];

const prepaidFields = [
  'prepaidInterest15days',
  'prepaidPropIns14days',
  'prepaidTaxes2mo',
  'prepaidMortIns1mo',
];

const totalEstimatedPayment = ['principalInterest', 'taxesInsurance', 'mortgageInsurance'];

const estPreClosingCosts = ['propertyTaxes', 'otherItemsTotal', 'commissionOwed'];

const estimatedSellerPaidClosingCosts = [
  'warrantyDeed',
  'escrowFee',
  'recording',
  'homeWarranty',
  'ownersTitlePolicy',
  'lienRelease',
  'allowanceOtherTotal',
];

export default function PaymentDashboard({ calculations, isLoading, isBuyer, showNextSteps, onNextStepsCallback, driverObj }) {
  const classes = useStyles();
  const calculationEntries = Object.entries(calculations);
  const sortedCalculationEntries = isBuyer
    ? sortCalculationEntries(calculationEntries)
    : calculationEntries.sort((a) => (a[0] === 'desiredPayment' ? -1 : 0));

  const [showMore, setShowMore] = useState(false);
  const [mounted, setMounted] = useState(false);

  const matchesSm = useMediaQuery('(max-width:400px)');
  const colTitleSize = matchesSm ? 4 : 6;
  const colValueSize = matchesSm ? 4 : 3;

  const collapsableFields = isBuyer
    ? ['estimateClosingCosts', 'estPrepaids', 'totalEstimatedPayment']
    : ['estimatedSellerPaidClosingCosts', 'estPreClosingCosts'];
  const showLessFields = isBuyer
    ? ['betterOrWorseFundsToClose', 'desiredPayment', 'homePrice', 'betweenWithDesired']
    : [
      'homePrice',
      'netFromPriceLessLoan',
      'sellerNetBeforeClosing',
      'totalSellerPaidClosingCosts',
      'estimatedNetProceeds',
    ];

  const [open, setOpen] = useState({
    estimateClosingCosts: false,
    estPrepaids: false,
    totalEstimatedPayment: false,
    estPreClosingCosts: false,
    estimatedSellerPaidClosingCosts: false,
  });

  const numberOptionsCurrency = {
    style: 'currency',
    currency: DEFAULT_CURRENCY,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    roundingMode: 'ceil',
  };

  const numberOptionsPercent = {
    style: 'percent',
  };

  const getTableCellClassName = (cellKey, cellValue) => {
    let isDanger = false;
    if (cellValue < 0) {
      isDanger = true;
    }
    if (
      cellKey === 'totalEstimatedPayment'
      || cellKey === 'betweenWithDesired'
      || cellKey === 'betterOrWorseFundsToClose'
      || cellKey === 'estimatedSellerPaidClosingCosts'
      || cellKey === 'estPreClosingCosts'
      || cellKey === 'sellerNetBeforeClosing'
      || cellKey === 'estimatedNetProceeds'
      || cellKey === 'netFromPriceLessLoan'
      || cellKey === 'totalSellerPaidClosingCosts'
    ) {
      return isDanger ? `${classes.dangerText} ${classes.boldText}` : classes.boldText;
    }
    if (
      cellKey === 'estimateClosingCosts'
      || cellKey === 'estPrepaids'
      || cellKey === 'totalEstimatedPayment'
      || feeFields.includes(cellKey)
      || prepaidFields.includes(cellKey)
      || totalEstimatedPayment.includes(cellKey)
    ) {
      return isDanger
        ? `${classes.estimatedFields} ${classes.dangerText}`
        : classes.estimatedFields;
    }
    if (cellKey === 'requiredIncome') {
      return classes.requiredIncomeStyle;
    }
    if (isDanger) return classes.dangerText;

    return null;
  };

  const renderNumberRow = (key, value) => {
    if (key === 'percentLoanToValue') return <FormattedNumber {...numberOptionsPercent} value={value} />;
    
    const val = new Big(value).round(0, Big.roundUp).toNumber();

    return val < 0 ? (
      <Box component="span" fontSize={{ xs: 10, sm: 14 }}>
        {'( '}
        <FormattedNumber {...numberOptionsCurrency} value={val*-1} />
        {' )'}
      </Box>
    ) : (
      <Box component="span" fontSize={{ xs: 10, sm: 14 }}>
        <FormattedNumber {...numberOptionsCurrency} value={val} />
      </Box>
    );
  };

  const renderDashboardRow = (key, value) => (
    <>
      <TableCell colSpan={colValueSize} className={getTableCellClassName(key, value[0])}>
        {isLoading ? <Skeleton /> : renderNumberRow(key, value[0])}
      </TableCell>
      <TableCell colSpan={colValueSize} className={getTableCellClassName(key, value[1])}>
        {isLoading ? <Skeleton /> : renderNumberRow(key, value[1])}
      </TableCell>
    </>
  );

  function renderSubRows([key, value]) {
    return (
      <React.Fragment key={key}>
        <TableRow className={classes.collapsableRow}>
          <TableCell
            className={`${getTableCellClassName(key)} ${classes.collapsableCell}`}
            size="small"
            colSpan={6}
            align="right"
          >
            <FormattedMessage {...messages[key]} />
          </TableCell>
          <TableCell
            className={`${getTableCellClassName(key, value[0])} ${classes.collapsableCell}`}
            size="small"
            colSpan={3}
          >
            {isLoading ? <Skeleton /> : renderNumberRow(key, value[0])}
          </TableCell>
          <TableCell
            className={`${getTableCellClassName(key, value[1])} ${classes.collapsableCell}`}
            size="small"
            colSpan={3}
          >
            {isLoading ? <Skeleton /> : renderNumberRow(key, value[1])}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const renderFeeCollapsable = () => {
    const feeFieldsFiltered = Object.entries(calculations).filter((entry) => feeFields.includes(entry[0]));

    // eslint-disable-next-line no-use-before-define
    return feeFieldsFiltered.map(renderSubRows);
  };
  const renderEstPreClosingCosts = () => {
    const estPreClosingCostsFiltered = Object.entries(calculations).filter((entry) => estPreClosingCosts.includes(entry[0]));

    // eslint-disable-next-line no-use-before-define
    return estPreClosingCostsFiltered.map(renderSubRows);
  };
  const renderEstimatedSellerPaidClosingCosts = () => {
    const estimatedSellerPaidClosingCostsFiltered = Object.entries(calculations).filter((entry) => estimatedSellerPaidClosingCosts.includes(entry[0]));

    // eslint-disable-next-line no-use-before-define
    return estimatedSellerPaidClosingCostsFiltered.map(renderSubRows);
  };

  const renderTotalEstimatedPaymentCollapsable = () => {
    const totalEstimatedPaymentFieldsFiltered = Object.entries(calculations).filter((entry) => totalEstimatedPayment.includes(entry[0]));

    // eslint-disable-next-line no-use-before-define
    return totalEstimatedPaymentFieldsFiltered.map(renderSubRows);
  };

  const renderPrepaidCollapsable = () => {
    const prepaidFieldsFiltered = Object.entries(calculations).filter((entry) => prepaidFields.includes(entry[0]));

    // eslint-disable-next-line no-use-before-define
    return prepaidFieldsFiltered.map(renderSubRows);
  };

  const getCollapsableElement = (fieldName) => {
    if (fieldName === 'estimateClosingCosts') {
      return renderFeeCollapsable();
    }
    if (fieldName === 'totalEstimatedPayment') {
      return renderTotalEstimatedPaymentCollapsable();
    }
    if (fieldName === 'estPreClosingCosts') {
      return renderEstPreClosingCosts();
    }
    if (fieldName === 'estimatedSellerPaidClosingCosts') {
      return renderEstimatedSellerPaidClosingCosts();
    }
    return renderPrepaidCollapsable();
  };

  const validateHighlightRefresh = () => {
    if (driverObj.isActive()) {
      const activeElement = driverObj.getActiveElement();
      if (activeElement?.id === 'payment-dashboard') {
        driverObj.refresh();
      }
    }
  }

  const toggleShowMore = () => {
    const newValue = !showMore;
    setShowMore(newValue);
    setTimeout(() => validateHighlightRefresh(), newValue ? 410 : 0);

  }

  const renderCollapse = (fieldName) => (
    <Collapse in={open[fieldName]} timeout="auto" unmountOnExit>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableBody className={classes.collapsedBody}>{getCollapsableElement(fieldName)}</TableBody>
      </Table>
    </Collapse>
  );

  const renderRow = ([key, value]) => {
    // Thouse fields render in collapses functions
    if (feeFields.includes(key)) return null;
    if (prepaidFields.includes(key)) return null;
    if (totalEstimatedPayment.includes(key)) return null;
    if (estPreClosingCosts.includes(key)) return null;
    if (estimatedSellerPaidClosingCosts.includes(key)) return null;

    const className = getTableCellClassName(key);
    const classRow = showLessFields.indexOf(key) >= 0 ? classes.important : classes.notImportant;

    let translateKey = key;
    if (key === 'betweenWithDesired') translateKey = 'betweenWithDesiredFix';
    if (!messages[translateKey]) return null;
    return (
      <React.Fragment key={key}>
        {classRow !== classes.important ? (
          <>
            {showMore ? (
              <>
                <TableRow key={key} className={classRow}>
                  <TableCell
                    className={clsx(className, classes.longWord)}
                    size="small"
                    colSpan={!Array.isArray(value) ? 12 : colTitleSize}
                  >
                    <FormattedMessage {...messages[translateKey]} />
                    {collapsableFields.includes(key) ? (
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          setOpen({ ...open, [key]: !open[key] });
                          setMounted(true);
                          validateHighlightRefresh();
                        }}
                      >
                        {open[key] ? (
                          <KeyboardArrowUpIcon className={mounted ? classes.active : ''} />
                        ) : (
                          <KeyboardArrowDownIcon className={mounted ? classes.active : ''} />
                        )}
                      </IconButton>
                    ) : null}
                  </TableCell>
                  {!Array.isArray(value) ? null : renderDashboardRow(key, value)}
                </TableRow>
                {collapsableFields.includes(key) && open[key] ? (
                  <TableRow key={`${key}-collapsable`}>
                    <TableCell colSpan={12} size="small" className={classes.collapsParent}>
                      {renderCollapse(key)}
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            ) : null}
          </>
        ) : (
          <>
            <TableRow key={key} className={clsx(classRow, { active: showMore })}>
              <TableCell
                className={clsx(className, classes.longWord)}
                size="small"
                colSpan={colTitleSize}
              >
                <FormattedMessage {...messages[translateKey]} />
                {collapsableFields.includes(key) ? (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {setOpen({ ...open, [key]: !open[key] }); validateHighlightRefresh();}}
                  >
                    {open[key] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                ) : null}
              </TableCell>
              {renderDashboardRow(key, value)}
            </TableRow>
            {collapsableFields.includes(key) && open[key] ? (
              <TableRow key={`${key}-collapsable`}>
                <TableCell colSpan={12} className={classes.collapsParent}>
                  {renderCollapse(key)}
                </TableCell>
              </TableRow>
            ) : null}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <Paper id='payment-dashboard' elevation={5}>
      <Box py={3}>
        <Typography variant="h5">
          <Box mb={3.125} ml={3.125}>
            <FormattedMessage {...messages.title} />
          </Box>
        </Typography>
        <TableContainer component={Box}>
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell colSpan={colTitleSize} />
                <TableCell colSpan={colValueSize} className={classes.tableHeadCell}>
                  <Typography color="primary" variant="caption" className={classes.heading}>
                    {isBuyer ? (
                      <FormattedMessage {...messages.desired} />
                    ) : (
                      <FormattedMessage {...messages.listPrice} />
                    )}
                  </Typography>
                </TableCell>
                <TableCell colSpan={colValueSize} className={classes.tableHeadCell}>
                  <Typography color="primary" variant="caption" className={classes.heading}>
                    {isBuyer ? (
                      <FormattedMessage {...messages.top} />
                    ) : (
                      <FormattedMessage {...messages.offerPrice} />
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{sortedCalculationEntries.map(renderRow)}</TableBody>
          </Table>
        </TableContainer>
        <Box textAlign="center" mt={3.125}>
          <Box display="inline-block" mr={2} mb={{ xs: 1, sm: 0 }}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => toggleShowMore()}
            >
              <FormattedMessage {...(!showMore ? messages.showAll : messages.collapseAll)} />
            </Button>
          </Box>
          {
              showNextSteps 
                ? <Button
                    color="primary" size="large" variant="contained"
                    onClick={() => onNextStepsCallback()}
                  >
                    <FormattedMessage {...messages.nextSteps} />
                  </Button>
                : null
            }
        </Box>
      </Box>
    </Paper>
  );
}

PaymentDashboard.propTypes = {
  isBuyer: PropTypes.bool.isRequired,
  calculations: calculationsPropTypes.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showNextSteps: PropTypes.bool,
  onNextStepsCallback: PropTypes.func,
  driverObj: PropTypes.object
};
