import messages from './messages';

const validate = (intl) => (values) => {
  const errors = {};

  if (!values.termOfLoan) {
    errors.termOfLoan = intl.formatMessage(messages.requiredTermOfLoan);
  }
  if (!values.userInterestRate || (typeof values.userInterestRate !== 'number')) {
    errors.userInterestRate = intl.formatMessage(messages.requiredUserInterestRate);
  }
  if (!values.percentOfFundsTowardsLoan) {
    errors.percentOfFundsTowardsLoan = intl.formatMessage(
      messages.requiredPercentOfFundsTowardsLoan,
    );
  }
  if (!values.downPayment) {
    errors.downPayment = intl.formatMessage(messages.requiredDownPayment);
  }
  if (!values.monthlyPayment) {
    errors.monthlyPayment = intl.formatMessage(messages.requiredMonthlyPayment);
  }

  return errors;
};

export default validate;
