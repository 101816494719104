import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'Unknown.Ppolicy.header',
    defaultMessage: 'Real Genius Privacy Policy for Customers and Website Visitors',
  },
  startIntro: {
    id: 'Unknown.Ppolicy.startIntro',
    defaultMessage: 'Real Genius,cares about the privacy of individuals who visit our website at',
  },
  endIntro: {
    id: 'Unknown.Ppolicy.endIntro',
    defaultMessage: 'and otherwise do business with us. This Privacy Policy explains how Real Geniuscollects, uses, processes, discloses, and safeguards information we obtain from and about our customers, visitors to our Sites, and business prospects and partners (collectively, “You”), including information we collect when you visit our websites or other services offered by Real Genius, or purchase our products and services (collectively, our “Services”). It also tells you about your rights and choices with respect to your information, and how you can contact us if you have any questions or concerns.',
  },
  homeLink: {
    id: 'Unknown.Ppolicy.homeLink',
    defaultMessage: 'https://realgenius.io/',
  },
  oneHeader: {
    id: 'Unknown.Ppolicy.oneHeader',
    defaultMessage: '1.How Real GeniusCollects Information',
  },
  oneBasicPOne: {
    id: 'Unknown.Ppolicy.oneBasicPOne',
    defaultMessage: `For the purpose ofthis Privacy Policy, “Personal Information” means any information
    relating to an identified or identifiable individual. We obtain Personal Information
    relating to you, our customer, from various sources described below.`,
  },
  oneBasicPTwo: {
    id: 'Unknown.Ppolicy.oneBasicPTwo',
    defaultMessage: `Where applicable, we indicate whether and why you must provide us with Personal
    Information, as well as the consequences of failing to do so. If you do not provide
    Personal Information when requested, you may not be able to benefit from our Services if
    that information is necessary to provide you with the service or if we are legally
    required to collect it.`,
  },
  onePunktA: {
    id: 'Unknown.Ppolicy.onePunktA',
    defaultMessage: 'a)Registration, Orders, and Other Information You Provide',
  },
  oneAOne: {
    id: 'Unknown.Ppolicy.oneAOne',
    defaultMessage: 'We collect your information, including your sign-in credentials, name, email address, home or property addressand phone number, when you create an account, sign in or request a calculation to either buy or sale a home.The types of information we may collect for payments to our services may include credit card and other payment information, and any other information you choose to provide.',
  },
  onePunktB: {
    id: 'Unknown.Ppolicy.onePunktB',
    defaultMessage: 'b)Communications',
  },
  oneBOne: {
    id: 'Unknown.Ppolicy.oneBOne',
    defaultMessage: 'If you contact us directly, such as to request information or access to the Services, or for Customer Support, we may receive additional information about you, such as your contact information and the contents of your communication. We may receive information when you complete a form on our Sites.',
  },
  onePunktC: {
    id: 'Unknown.Ppolicy.onePunktC',
    defaultMessage: 'c)Device Information',
  },
  oneCOne: {
    id: 'Unknown.Ppolicy.oneCOne',
    defaultMessage: 'We receive information about your device, including IP address, web browser type, operating system version, mobile device model, device manufacturer and model, language codes, your Internet Service Provider (ISP), unique device identifiers.',
  },
  onePunktD: {
    id: 'Unknown.Ppolicy.onePunktD',
    defaultMessage: 'd)Usage Information',
  },
  oneDOne: {
    id: 'Unknown.Ppolicy.oneDOne',
    defaultMessage: 'We automatically receive information about your interactions with our Services, like the pages or other content you view, the dates and times of your interactions, the searches you conduct, how often you use our Services, where you downloaded the application from.',
  },
  onePunktE: {
    id: 'Unknown.Ppolicy.onePunktE',
    defaultMessage: 'e)Location Data',
  },
  oneEOne: {
    id: 'Unknown.Ppolicy.oneEOne',
    defaultMessage: 'We may infer your location from information we collect (for example, your IP address indicates the general geographic region from which you are connecting to the Internet). We may use location information to provide you with features, notifications, marketing, or other content that is influenced by your location (such as showing you the Services in your local language).',
  },
  onePunktF: {
    id: 'Unknown.Ppolicy.onePunktF',
    defaultMessage: 'f)Cookies and Similar Technologies',
  },
  oneFOne: {
    id: 'Unknown.Ppolicy.oneFOne',
    defaultMessage: 'A cookie is a text-only string of information that a website transfers to the cookie file of the browser on your computer or mobile device. When you use our Services, we and our third-party service providers may collect information from you through cookies, web beacons, web server logs, and similar technologies. We may use both session cookies and persistent cookies. By using the Services, you consent to the placement of cookies, beacons, and similar technologies in your browser and on emails in accordance with this Privacy Policy. If you disable cookies on your browser, some parts of the Services may not work fully.',
  },
  oneFTwo: {
    id: 'Unknown.Ppolicy.oneFTwo',
    defaultMessage: 'We work with analytics providers such as Google Analytics, which use cookies and similar technologies to collect and analyze information about use of the Services and report on activities and trends. Google Analytics may also collect information regarding the use of other websites, apps and online resources. You can learn about Google’s practices by going to www.google.com/policies/privacy/partners/, and opt out of them by downloading the Google Analytics opt-out browser add-on, available at https://tools.google.com/dlpage/gaoptout.',
  },
  oneFThree: {
    id: 'Unknown.Ppolicy.oneFThree',
    defaultMessage: 'Our Services may include links, features or components supplied by third parties, such as the Facebook “Like” button or other interactive tools. Such third-parties may have information practices different than those set forth herein and their use of cookies and similar technologies is not covered by this Privacy Policy. We do not have access to or control over such third parties and encourage you to consult the privacy notices provided by those third parties.',
  },
  twoHeader: {
    id: 'Unknown.Ppolicy.twoHeader',
    defaultMessage: '2.How Real Genius Uses Information',
  },
  twoBasicOne: {
    id: 'Unknown.Ppolicy.twoBasicOne',
    defaultMessage: 'Real Genius will use information, including Personal Information, for the following purposes:',
  },
  twoPunktA: {
    id: 'Unknown.Ppolicy.twoPunktA',
    defaultMessage: 'a)Providing the Service',
  },
  twoPunktB: {
    id: 'Unknown.Ppolicy.twoPunktB',
    defaultMessage: 'b)Facilitating Transactions',
  },
  twoPunktC: {
    id: 'Unknown.Ppolicy.twoPunktC',
    defaultMessage: 'c)Analytics',
  },
  twoPunktD: {
    id: 'Unknown.Ppolicy.twoPunktD',
    defaultMessage: 'd)Marketing and Advertising',
  },
  twoPunktE: {
    id: 'Unknown.Ppolicy.twoPunktE',
    defaultMessage: 'e)Communications',
  },
  twoAOne: {
    id: 'Unknown.Ppolicy.twoAOne',
    defaultMessage: 'We use your information to provide, enhance, improve, and personalize our Services.',
  },
  twoBOne: {
    id: 'Unknown.Ppolicy.twoBOne',
    defaultMessage: 'We use your payment information to facilitate your transactions and payments.',
  },
  twoCOne: {
    id: 'Unknown.Ppolicy.twoCOne',
    defaultMessage: 'We will use your information to understand and analyze how you use our Services.',
  },
  twoDOne: {
    id: 'Unknown.Ppolicy.twoDOne',
    defaultMessage: 'We will use your information for marketing purposes, such as contacting you about Real Geniusproductsand services, providing you with promotional materials that may be useful, relevant, valuable or otherwise of interest to you. We use Personal Information that we collect in connection with the Services, such as your location and your activities on the Services, to determine whether Real Geniusmay wish to contact you in order to offer you Real Geniusservices and to facilitate the delivery of advertisements. We may send you (a) information about topics or content that we think may interest you, or (b) updates about the latest developments or features on the Services. We also may send push notifications to your device and a newsletter or similar materials to the email address you provide to us in the event that you subscribe to receive such a newsletter.',
  },
  twoEOne: {
    id: 'Unknown.Ppolicy.twoEOne',
    defaultMessage: 'Real Geniuswill use your information to communicate with you, including to respond to your inquiries and to send emails to an email address you provide to us for customer-service or technical-support purposes.',
  },
  twoETwo: {
    id: 'Unknown.Ppolicy.twoETwo',
    defaultMessage: 'If you are located in the European Economic Area, we only process your Personal Information based on a valid legal ground, including when:',
  },
  twoEListOne: {
    id: 'Unknown.Ppolicy.twoEListOne',
    defaultMessage: 'You have consented to the use of your Personal Information, for example, to receive marketing communications. You may withdraw any consent you previously provided to us regarding the processing of your Personal Information, at any time and free of charge. We will apply your preferences going forward and this will not affect the lawfulness of the processing before your consent withdrawal;',
  },
  twoEListTwo: {
    id: 'Unknown.Ppolicy.twoEListTwo',
    defaultMessage: 'We need your Personal Information to provide you with the Services, including for account registration, or to respond to your inquiries;',
  },
  twoEListThree: {
    id: 'Unknown.Ppolicy.twoEListThree',
    defaultMessage: 'We have a legal obligation to use your Personal Information; and',
  },
  twoEListFour: {
    id: 'Unknown.Ppolicy.twoEListFour',
    defaultMessage: 'We or a third party, have a legitimate interest in using your Personal Information. In particular, we have a legitimate interest in using your Personal Information to conduct business analytics, and otherwise improve the safety, security, and performance of our Services.',
  },
  threeHeader: {
    id: 'Unknown.Ppolicy.threeHeader',
    defaultMessage: '3.How Real GeniusDiscloses Information',
  },
  threePunktA: {
    id: 'Unknown.Ppolicy.threePunktA',
    defaultMessage: 'a)Corporate Affiliates',
  },
  threePunktB: {
    id: 'Unknown.Ppolicy.threePunktB',
    defaultMessage: 'b)Service Providers',
  },
  threePunktC: {
    id: 'Unknown.Ppolicy.threePunktC',
    defaultMessage: 'c)As Required by Law and Similar Disclosures',
  },
  threeAOne: {
    id: 'Unknown.Ppolicy.threeAOne',
    defaultMessage: 'Real Geniusmay share your information with and receive information from Real Geniusoffices throughout the world, subject to this Privacy Policy.',
  },
  threeBOne: {
    id: 'Unknown.Ppolicy.threeBOne',
    defaultMessage: 'We may share your information with our vendors, service providers, and other third parties that perform services on our behalf.',
  },
  threeCOne: {
    id: 'Unknown.Ppolicy.threeCOne',
    defaultMessage: 'We may disclose information about you: (i) if we are required to do so by law, regulation, or legal process, such as a courtorder or subpoena; (ii) in response to requests by government agencies, such as law enforcement authorities; (iii) when we believe disclosure is necessary or appropriate to protect against or respond to physical, financial or other harm, injury, or loss to property; or (iv) in connection with an investigation of suspected or actual unlawful activity.',
  },
  fourHeader: {
    id: 'Unknown.Ppolicy.fourHeader',
    defaultMessage: '4.Data Retention',
  },
  fourBasicOne: {
    id: 'Unknown.Ppolicy.fourBasicOne',
    defaultMessage: 'We take measures to delete your Personal Information or keep it in a form that does not permit identifying you when this information is no longer necessary for the purposes for which we process it, unless we are required by law to keep this information for a longer period or to, or such information is necessary to resolve disputes and enforce our agreements. When determining the retention period,we take into account various criteria, such as the type of products and services requested by or provided to you, the nature and length of our relationship with you, possible re-enrolment with our products or services, the impact on the services we provide if we delete some information from or about you, mandatory retention periods provided by law and the statute of limitations.',
  },
  fiveHeader: {
    id: 'Unknown.Ppolicy.fiveHeader',
    defaultMessage: '5.Our Commitment to Security',
  },
  fiveBasicOne: {
    id: 'Unknown.Ppolicy.fiveBasicOne',
    defaultMessage: 'Real Geniusemploys a variety of security technologies and procedures designed to help prevent unauthorized disclosure of, access to and use of Information that Real Geniuscollects or receives. However, Real Geniuscannot guarantee the security of your Information.',
  },
  sixHeader: {
    id: 'Unknown.Ppolicy.sixHeader',
    defaultMessage: '6.Do Not Track',
  },
  sixBasicOne: {
    id: 'Unknown.Ppolicy.sixBasicOne',
    defaultMessage: 'Some web browsers incorporate a “Do Not Track” feature. Because there is not yet an accepted standard for how to respond to Do Not Track signals, our website does not currently respond to such signals.',
  },
  sevenHeader: {
    id: 'Unknown.Ppolicy.sevenHeader',
    defaultMessage: '7.Your Rights and Choices',
  },
  sevenPunktA: {
    id: 'Unknown.Ppolicy.sevenPunktA',
    defaultMessage: 'a)Global Rights',
  },
  sevenAOne: {
    id: 'Unknown.Ppolicy.sevenAOne',
    defaultMessage: 'Upon request, Real Geniuswill provide you with information about whether we hold any of your Personal Information. You can update or correct Personal Information (e.g., your email address) by accessing your account. You can also access or rectify your information by reaching out to us at realgeniusinfo@gmail.com.You can delete your information by sending an email to realgeniusinfo@gmail.comwith your first name, last name, and the respective email addresses you would like for us to delete.',
  },
  sevenATwo: {
    id: 'Unknown.Ppolicy.sevenATwo',
    defaultMessage: 'To opt-out of receiving our newsletters or marketing emails, you can follow the unsubscribe instructions included in the emails communications you receive, access the email preferences in your account settings page, or contact us at the above email address. Please note that we have the right to reject deletion requests that are unduly burdensome or repetitive or that cannot be honored in light of legal obligations or ongoing disputes, or where retention is necessary to enforce our agreements or protect our or another party’s rights, property, safety, or security.',
  },
  eightHeader: {
    id: 'Unknown.Ppolicy.eightHeader',
    defaultMessage: '8.How to Contact Us',
  },
  eightBasicOne: {
    id: 'Unknown.Ppolicy.eightBasicOne',
    defaultMessage: 'If you have any questions or concerns about this Privacy Policy or its implementation, you may contact Real Geniusvia email (realgeniusinfo@gmail.com) or at the following address:',
  },
  eightBasicTwo: {
    id: 'Unknown.Ppolicy.eightBasicTwo',
    defaultMessage: 'Real Genius Management',
  },
  eightBasicThree: {
    id: 'Unknown.Ppolicy.eightBasicThree',
    defaultMessage: 'Phone: 972-768-0132',
  },
  nineHeader: {
    id: 'Unknown.Ppolicy.nineHeader',
    defaultMessage: '9.Updates to this Privacy Policy',
  },
  nineBasicOne: {
    id: 'Unknown.Ppolicy.nineBasicOne',
    defaultMessage: 'We will post any adjustments to this Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share Personal Information previously collected from you through our Service, we will notify you through our Service, by email, or other communication.',
  },
});
