/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTheme } from '@material-ui/core';
import Header from '../../../containers/User/HeaderContainer';
import Container from '../../Common/Container';
import Box from '../../Common/Box';
import InputAdornment from '../../Common/InputAdornment';
import MenuItem from '../../Common/MenuItem';
import Button from '../../Common/Button';
import Typography from '../../Common/Typography';
import messages from './messages';
import useStyles from './styles';
import Dialog from '../../Common/Dialog';
import TextField from '../../Common/TextField';
import DialogActions from '../../Common/DialogActions';
import DialogTitle from '../../Common/DialogTitle';
import Divider from '../../Common/Divider';
import Grid from '../../Common/Grid';
import CheckBox from '../../Common/Checkbox';
import FormControlLabel from '../../Common/FormControlLabel';
// import ClientListContainer from '../../../containers/User/ClientListContainer';
import { SearchBar } from '../../../common/Icons';
import ClientListContainer from '../../../containers/User/ClientListContainer';
import { AnalyticsContext } from '../../../common/analyticsContext';
import { AnalyticEventNames } from '../../../common/analyticEventNames.enum';
import { TenantContext } from '../../../common/tenantContext';
import { AuthContext } from '../../../common/authContext';

const sortOptions = ['oldest', 'newest'];

export default function DashboardScreen({
  sortBy, onChangeSortBy, onUpdateCard, deleteCard, clientsColumns, setClientsColumns,
}) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletableCardId, setDeletableCardId] = useState({ id: '', isConfirm: false });

  const [searchText, setSearchText] = useState('');
  const [showArchive, setShowArchive] = useState(false);
  const [showType, setShowType] = useState({ seller: true, buyer: true });

  const {trackEvent} = useContext(AnalyticsContext);
  const {tenant} = useContext(TenantContext);
  const {authUser} = useContext(AuthContext);

  const borderGray = `1px solid ${theme.palette.secondary.lightGray}`;

  const handleChange = (event) => onChangeSortBy(event.target.value);

  const renderTableHeader = () => (
    <>
      <Box display="flex" alignItems="center" mb={0.5} mt={1}>
        <Typography variant="h5">
          <FormattedMessage {...messages.allClients} />
        </Typography>
        <Button
          component={Link}
          to="/clients/create"
          color="primary"
          variant="contained"
          size="large"
          className={classes.viewButton}
          onClick={() => {
            trackEvent(AnalyticEventNames.RealtorInteraction, {realtorId: authUser?._id, tenantId: tenant?._id, action: 'Add new client'})
          }}
        >
          <FormattedMessage {...messages.addNew} />
        </Button>
      </Box>
      <Box my={2.5}>
        <Divider />
      </Box>
    </>
  );

  const renderSelect = () => (
    <Box mb={4}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Box display="flex">
            <TextField
              color="primary"
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={intl.formatMessage(messages.placeholder)}
              InputProps={{
                className: classes.searchBar,
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchBar />
                  </InputAdornment>
                ),
              }}
            />
            <Box ml={9.5}>
              <FormControlLabel control={<CheckBox checked={showType.buyer} onChange={() => setShowType({ ...showType, buyer: !showType.buyer })} />} label="Buyer" labelPlacement="end" />
              <FormControlLabel control={<CheckBox checked={showType.seller} onChange={() => setShowType({ ...showType, seller: !showType.seller })} />} label="Seller" labelPlacement="end" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Box display="flex" width="50%" justifyContent="flex-end">
              <FormControlLabel
                // eslint-disable-next-line max-len
                control={(
                  <CheckBox
                    value={showArchive}
                    checked={showArchive}
                    onChange={() => setShowArchive(!showArchive)}
                  />
                )}
                label={intl.formatMessage(messages.showArchive)}
                labelPlacement="end"
              />
            </Box>
            <Box display="flex" alignItems="center" ml={6} width="50%" justifyContent="flex-end">
              <Typography variant="body1">
                <FormattedMessage {...messages.sortBy} />
              </Typography>
              <TextField
                color="primary"
                variant="outlined"
                size="small"
                placeholder={intl.formatMessage(messages.newest)}
                className={classes.sortBy}
                select
                value={sortBy}
                onChange={handleChange}
                inputProps={{
                  className: classes.sortValue,
                }}
              >
                {sortOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage {...messages[option]} />
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderColumns = () => (
    <Grid container className={classes.columnContainer}>
      <Grid item xs={3}>
        <Typography
          variant="subtitle2"
          align="center"
          color="primary"
          className={classes.columnTitle}
        >
          <FormattedMessage {...messages.potential} />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="subtitle2"
          align="center"
          color="primary"
          className={classes.columnTitle}
        >
          <FormattedMessage {...messages.active} />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="subtitle2"
          align="center"
          color="primary"
          className={classes.columnTitle}
        >
          <FormattedMessage {...messages.underContract} />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="subtitle2"
          align="center"
          color="primary"
          className={classes.columnTitle}
        >
          <FormattedMessage {...messages.closed} />
        </Typography>
      </Grid>
    </Grid>
  );

  const onDeleteCard = (id) => {
    setOpenDeleteConfirmation(true);
    setDeletableCardId({ isConfirm: false, id });
  };

  const onCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleDelete = () => {
    if (deletableCardId.id) {
      setDeletableCardId({ id: deletableCardId.id, isConfirm: true });
      deleteCard(deletableCardId.id);
    }
    onCloseDeleteConfirmation();
  };

  return (
    <Box minWidth="768px" mb={{ xs: 36, sm: 0 }}>
      <Header />
      <Container maxWidth="xl" component="div" disableGutters>
        <Dialog
          open={openDeleteConfirmation}
          onClose={onCloseDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage {...messages.deleteConfirmation} />
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete} color="primary" autoFocus>
              <FormattedMessage {...messages.confirm} />
            </Button>
            <Button onClick={onCloseDeleteConfirmation} color="primary">
              <FormattedMessage {...messages.notConfirm} />
            </Button>
          </DialogActions>
        </Dialog>
        <Box p={4}>
          {renderTableHeader()}
          {renderSelect()}

          <Box className={classes.tableContainer}>
            {renderColumns()}
            <DragDropContext
              // eslint-disable-next-line max-len
              onDragEnd={(result) => {
                if (
                  result.destination
                  && result.destination.droppableId !== result.source.droppableId
                ) {
                  onUpdateCard({ _id: result.draggableId, status: result.destination.droppableId });
                  const sourceItems = [...clientsColumns[result.source.droppableId]];
                  let destinationItems = [...clientsColumns[result.destination.droppableId]];
                  const [removed] = sourceItems.splice(result.source.index, 1);
                  destinationItems.splice(result.destination.index, 0, removed);
                  destinationItems = destinationItems.sort((a, b) => {
                    if (sortBy === 'oldest') {
                      return ((a.createdAt instanceof Date) ? a.createdAt.getMilliseconds() : 0)
                        - ((b.createdAt instanceof Date) ? b.createdAt.getMilliseconds() : 0);
                    }
                    return ((b.createdAt instanceof Date) ? b.createdAt.getMilliseconds() : 0)
                        - ((a.createdAt instanceof Date) ? a.createdAt.getMilliseconds() : 0);
                  });
                  trackEvent(AnalyticEventNames.ClientStatusUpdated, {
                    _id: result.draggableId,
                    status: result.destination.droppableId,
                    tenantId: removed.tenantId,
                    tenantName: tenant.name,
                    updatedBy: authUser._id
                  })
                  setClientsColumns({
                    ...clientsColumns,
                    [result.destination.droppableId]: destinationItems,
                    [result.source.droppableId]: sourceItems,
                  });
                }
              }}
            >
              <Grid container spacing={3}>
                {Object.entries(clientsColumns).map(([status, clients], index) => (
                  <Grid item xs={3} key={status} className={classes.cardContainer}>
                    <Box
                      height="750px"
                      borderLeft={index > 0 ? borderGray : 'none'}
                    >
                      <SimpleBar style={{ maxHeight: 750, height: 750 }} autoHide={false} scrollbarMaxSize={30}>
                        <Droppable droppableId={status}>
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                height: 690,
                              }}
                            >
                              <Box py={2}>
                                <ClientListContainer
                                  sortBy={sortBy}
                                  onUpdateCard={onUpdateCard}
                                  onDeleteCard={onDeleteCard}
                                  statusCol={status}
                                  deletableCard={deletableCardId}
                                  clients={clients}
                                  searchText={searchText}
                                  showArchive={showArchive}
                                  showType={showType}
                                  // eslint-disable-next-line max-len
                                  setClients={(newClients) => setClientsColumns((state) => ({ ...state, [status]: newClients }))}
                                  setDeletableCard={setDeletableCardId}
                                />
                              </Box>
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </SimpleBar>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DragDropContext>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

DashboardScreen.propTypes = {
  sortBy: PropTypes.string.isRequired,
  onChangeSortBy: PropTypes.func.isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
  setClientsColumns: PropTypes.func.isRequired,
  clientsColumns: PropTypes.object.isRequired,
};
