import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import Box from '../../Common/Box';
import ClientCard from '../ClientCard';
import clientPropTypes from '../clientPropTypes';

export default function ClientList({
  clients, onUpdateCard, onDeleteCard,
}) {
  return (
    <Box maxWidth={441} margin="auto" px={3}>
      {clients.map((client, index) => (
        <Draggable index={index} key={client._id} draggableId={client._id}>
          {(provided, snapshot) => (
            <div {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps}>
              <Box mb={2.5} maxWidth={393}>
                <ClientCard
                  onUpdateCard={onUpdateCard}
                  onDeleteCard={onDeleteCard}
                  {...client}
                  updatedAt={new Date(client.updatedAt ?? client.createdAt)}
                  isDragging={snapshot.isDragging}
                />
              </Box>
            </div>
          )}
        </Draggable>
      ))}
    </Box>
  );
}

ClientList.propTypes = {
  clients: PropTypes.arrayOf(clientPropTypes).isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  onDeleteCard: PropTypes.func.isRequired,
};
