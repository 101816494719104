import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
  },
}));

export default useStyles;
