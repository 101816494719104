import { defineMessages } from 'react-intl';

export default defineMessages({
  clickCalculate: {
    id: 'Payment.PaymentCalculationForm.clickCalculate',
    defaultMessage: 'Click to Calculate',
  },
  listPrice: {
    id: 'Payment.PaymentCalculationForm.listPrice',
    defaultMessage: 'List price',
  },
  offerPrice: {
    id: 'Payment.PaymentCalculationForm.offerPrice',
    defaultMessage: 'Offer price',
  },
  listingNumber: {
    id: 'Payment.PaymentCalculationForm.listingNumber',
    defaultMessage: 'Listing Number',
  },
  listDate: {
    id: 'Payment.PaymentCalculationForm.listDate',
    defaultMessage: 'List Date',
  },
  offerDate: {
    id: 'Payment.PaymentCalculationForm.offerDate',
    defaultMessage: 'Offer Date',
  },
  closeDate: {
    id: 'Payment.PaymentCalculationForm.closeDate',
    defaultMessage: 'Close Date',
  },
  propertyAdress: {
    id: 'Payment.PaymentCalculationForm.propertyAdress',
    defaultMessage: 'Property Adress',
  },
  agentCommission: {
    id: 'Payment.PaymentCalculationForm.agentCommission',
    defaultMessage: 'Agent Commission',
  },
  estimateBalanceOne: {
    id: 'Payment.PaymentCalculationForm.estimateBalanceOne',
    defaultMessage: 'Estimate Balance 1st',
  },
  estimateBalanceTwo: {
    id: 'Payment.PaymentCalculationForm.estimateBalanceTwo',
    defaultMessage: 'Estimate Balance 2nd',
  },
  otherItem: {
    id: 'Payment.PaymentCalculationForm.otherItem',
    defaultMessage: 'Other Item',
  },
  allowanceOther: {
    id: 'Payment.PaymentCalculationForm.allowanceOther',
    defaultMessage: 'Allowance Other',
  },
  requiredListValue: {
    id: 'Payment.PaymentCalculationForm.requiredListValue',
    defaultMessage: 'List Price Is Required',
  },
  requiredOfferValue: {
    id: 'Payment.PaymentCalculationForm.requiredOfferValue',
    defaultMessage: 'Offer Price Is Required',
  },
  requiredCloseDate: {
    id: 'Payment.PaymentCalculationForm.requiredCloseDate',
    defaultMessage: 'Close Date is Required',
  },
  requiredAgentCmmission: {
    id: 'Payment.PaymentCalculationForm.requiredAgentCmmission',
    defaultMessage: 'Agent Commission is Required',
  },
  requiredEstimateBalance: {
    id: 'Payment.PaymentCalculationForm.requiredEstimateBalance',
    defaultMessage: 'Estimate Balance is Required',
  },
  requiredOtherItem: {
    id: 'Payment.PaymentCalculationForm.requiredOtherItem',
    defaultMessage: 'Other Item is Required',
  },
  state: {
    id: 'Payment.PaymentCalculationForm.state',
    defaultMessage: 'State',
  },
  city: {
    id: 'Payment.PaymentCalculationForm.city',
    defaultMessage: 'City',
  },
  zip: {
    id: 'Payment.PaymentCalculationForm.zip',
    defaultMessage: 'Zip',
  },
});
