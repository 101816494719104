import { Calculation } from '../models/calculation.model';
import { getToken, validateAndExtractData } from './api.base.service';

export const getCalculations = async (tenantId: string, clientId: string): Promise<Calculation[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}calculation?tenantId=${tenantId}&clientId=${clientId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      }
    }
  );
  const calculations: Calculation[] = await validateAndExtractData(response);
  // Format fields
  calculations.forEach(c => {
    if (c['listDate']) c['listDate'] = new Date(c['listDate']);
    if (c['closeDate']) c['closeDate'] = new Date(c['closeDate']);
    if (c['offerDate']) c['offerDate'] = new Date(c['offerDate']);
  })

  return calculations;
};

export const createCalculation = async (calc: Partial<Calculation>): Promise<Calculation> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}calculation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      },
      body: JSON.stringify(calc)
    }
  );
  const calculation: Calculation = await validateAndExtractData(response);
  // Format fields
  if (calculation['listDate']) calculation['listDate'] = new Date(calculation['listDate']);
  if (calculation['closeDate']) calculation['closeDate'] = new Date(calculation['closeDate']);
  if (calculation['offerDate']) calculation['offerDate'] = new Date(calculation['offerDate']);

  return calculation;
};

