import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Payment.PaymentDashboard.title',
    defaultMessage: 'Payment Dashboard',
  },
  top: {
    id: 'Payment.PaymentDashboard.top',
    defaultMessage: 'Top',
  },
  desired: {
    id: 'Payment.PaymentDashboard.desired',
    defaultMessage: 'Desired',
  },
  listPrice: {
    id: 'Payment.PaymentDashboard.listPrice',
    defaultMessage: 'List price',
  },
  offerPrice: {
    id: 'Payment.PaymentDashboard.offerPrice',
    defaultMessage: 'Offer price',
  },
  loanAmount: {
    id: 'Payment.PaymentDashboard.loanAmount',
    defaultMessage: 'Loan Amount',
  },
  principalInterest: {
    id: 'Payment.PaymentDashboard.principalInterest',
    defaultMessage: 'Principal & Interest',
  },
  taxesInsurance: {
    id: 'Payment.PaymentDashboard.taxesInsurance',
    defaultMessage: 'Taxes & Insurance',
  },
  mortgageInsurance: {
    id: 'Payment.PaymentDashboard.mortgageInsurance',
    defaultMessage: 'Mortgage Insurance',
  },
  totalEstimatedPayment: {
    id: 'Payment.PaymentDashboard.totalEstimatedPayment',
    defaultMessage: 'Total Estimated Payment',
  },
  totalSellerPaidClosingCosts: {
    id: 'Payment.PaymentDashboard.totalSellerPaidClosingCosts',
    defaultMessage: 'Est. Seller Closing Cost & Allowances',
  },
  desiredPayment: {
    id: 'Payment.PaymentDashboard.desiredPayment',
    defaultMessage: 'Desired payment',
  },
  betweenWithDesiredFix: {
    id: 'Payment.PaymentDashboard.betweenWithDesiredFix',
    defaultMessage: 'B/(W) to Desired Payment',
  },
  requiredIncome: {
    id: 'Payment.PaymentDashboard.estimatedIncomeRequired',
    defaultMessage: 'Estimated Income Required',
  },
  percentLoanToValue: {
    id: 'Payment.PaymentDashboard.percentLoanToValue',
    defaultMessage: '% Loan to Value',
  },
  homePrice: {
    id: 'Payment.PaymentDashboard.homePrice',
    defaultMessage: 'Home Price',
  },
  downPaymentDashboard: {
    id: 'Payment.PaymentDashboard.downPaymentDashboard',
    defaultMessage: '$ Down Payment',
  },
  loanOrigFee: {
    id: 'Payment.PaymentDashboard.loanOrigFee',
    defaultMessage: 'Origination Fee',
  },
  administrationFee: {
    id: 'Payment.PaymentDashboard.administrationFee',
    defaultMessage: 'Administration Fee',
  },
  appraisalTitlePolicy: {
    id: 'Payment.PaymentDashboard.appraisalTitlePolicy',
    defaultMessage: 'Appraisal Fee',
  },
  otherFees: {
    id: 'Payment.PaymentDashboard.otherFees',
    defaultMessage: 'Other Fees',
  },
  estimateClosingCosts: {
    id: 'Payment.PaymentDashboard.estimateClosingCosts',
    defaultMessage: 'Est. Closing Costs',
  },
  prepaidInterest15days: {
    id: 'Payment.PaymentDashboard.prepaidInterest15days',
    defaultMessage: 'Prepaid:  Interest (15 days)',
  },
  prepaidPropIns14days: {
    id: 'Payment.PaymentDashboard.prepaidPropIns14days',
    defaultMessage: 'Prepaid:  Prop. Ins. (14 months)',
  },
  prepaidTaxes2mo: {
    id: 'Payment.PaymentDashboard.prepaidTaxes2mo',
    defaultMessage: 'Prepaid: * Taxes (2 mo)',
  },
  prepaidMortIns1mo: {
    id: 'Payment.PaymentDashboard.prepaidMortIns1mo',
    defaultMessage: 'Prepaid:  Mort. Ins. (1 mo)',
  },
  estPrepaids: {
    id: 'Payment.PaymentDashboard.estPrepaids',
    defaultMessage: 'Est. Prepaids',
  },
  fundsRequiredToCloseABC: {
    id: 'Payment.PaymentDashboard.fundsRequiredToCloseABC',
    defaultMessage: 'Funds Required to Close',
  },
  fundsAvailableToClose: {
    id: 'Payment.PaymentDashboard.fundsAvailableToClose',
    defaultMessage: 'Funds Available to Close',
  },
  betterOrWorseFundsToClose: {
    id: 'Payment.PaymentDashboard.betterOrWorseFundsToClose',
    defaultMessage: 'Better/(Worse) Funds to Close',
  },
  interestRate: {
    id: 'Payment.PaymentDashboard.interestRate',
    defaultMessage: 'Interest Rate (Suggested)',
  },
  userInterestRate: {
    id: 'Payment.PaymentDashboard.userInterestRate',
    defaultMessage: 'Interest Rate set by user',
  },
  mortgageInsuranceRateUsedBasedOnLTV: {
    id: 'Payment.PaymentDashboard.mortgageInsuranceRateUsedBasedOnLTV',
    defaultMessage: 'Mortgage Insurance rate Used (based on LTV)',
  },
  propertyInsRate: {
    id: 'Payment.PaymentDashboard.propertyInsRate',
    defaultMessage: 'Property Ins. Rate',
  },
  desiredIncomeRatio: {
    id: 'Payment.PaymentDashboard.desiredIncomeRatio',
    defaultMessage: 'Desired Income Ratio',
  },
  propertyTaxRate: {
    id: 'Payment.PaymentDashboard.propertyTaxRate',
    defaultMessage: 'Property Tax Rate',
  },
  helpfulHint: {
    id: 'Payment.PaymentDashboard.helpfulHint',
    defaultMessage: 'Helpful Hint: Add more Down Pmt. Seller Paid Closing Costs or Lender Credit.',
  },
  showAll: {
    id: 'Payment.PaymentDashboard.showAll',
    defaultMessage: 'Show all',
  },
  collapseAll: {
    id: 'Payment.PaymentDashboard.collapseAll',
    defaultMessage: 'Collapse all',
  },
  netFromPriceLessLoan: {
    id: 'Payment.PaymentDashboard.netFromPriceLessLoan',
    defaultMessage: 'Gross Net (Price less liens)',
  },
  sellerNetBeforeClosing: {
    id: 'Payment.PaymentDashboard.sellerNetBeforeClosing',
    defaultMessage: 'Adjusted Net (exclude closing cost)',
  },
  estimatedNetProceeds: {
    id: 'Payment.PaymentDashboard.estimatedNetProceeds',
    defaultMessage: 'Estimated Net Proceeds',
  },
  totalLoanBalance: {
    id: 'Payment.PaymentDashboard.totalLoanBalance',
    defaultMessage: 'Total Loan Balance',
  },
  propertyTaxes: {
    id: 'Payment.PaymentDashboard.propertyTaxes',
    defaultMessage: 'Propertaty Taxes',
  },
  otherItemsTotal: {
    id: 'Payment.PaymentDashboard.otherItemsTotal',
    defaultMessage: 'Other Item (s)',
  },
  commissionOwed: {
    id: 'Payment.PaymentDashboard.commissionOwed',
    defaultMessage: 'Commissions Owed',
  },
  warrantyDeed: {
    id: 'Payment.PaymentDashboard.warrantyDeed',
    defaultMessage: 'Warranty Deed',
  },
  escrowFee: {
    id: 'Payment.PaymentDashboard.escrowFee',
    defaultMessage: 'Escrow Fee',
  },
  recording: {
    id: 'Payment.PaymentDashboard.recording',
    defaultMessage: 'Recording',
  },
  homeWarranty: {
    id: 'Payment.PaymentDashboard.homeWarranty',
    defaultMessage: 'Home Warranty',
  },
  ownersTitlePolicy: {
    id: 'Payment.PaymentDashboard.ownersTitlePolicy',
    defaultMessage: 'Owners Title Policy',
  },
  lienRelease: {
    id: 'Payment.PaymentDashboard.lienRelease',
    defaultMessage: 'Lien Release',
  },
  allowanceOtherTotal: {
    id: 'Payment.PaymentDashboard.allowanceOtherTotal',
    defaultMessage: 'Allowances/Other Total',
  },
  estPreClosingCosts: {
    id: 'Payment.PaymentDashboard.estPreClosingCosts',
    defaultMessage: 'Est. pre-closing costs',
  },
  estimatedSellerPaidClosingCosts: {
    id: 'Payment.PaymentDashboard.estimatedSellerPaidClosingCosts',
    defaultMessage: 'Estimated Seller Paid Closing Costs',
  },
  applyNowWithYourChoiceOfLender: {
    id: 'Payment.PaymentDashboard.applyNowWithYourChoiceOfLender',
    defaultMessage: 'Apply Now with your Choice of Lender',
  },
  nextSteps: {
    id: 'Payment.PaymentDashboard.nextSteps',
    defaultMessage: 'Next Steps',
  },
});
