import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import Box from '../Box';
import Grid from '../Grid';
import messages from './messages';
import useStyles from './styles';
import Button from '../Button';
import SubmitButton from '../SubmitButton';
import ClientFields from '../../User/ClientCreateScreen/ClientFields';
import FormControl from '../FormControl';
import FormLabel from '../FormLabel';
import validate from '../../User/ClientCreateScreen/validate';
import { clientTypes } from '../../Client/clientConstants';
import FormikRadioGroup from '../FormikRadioGroup';
import FormControlLabel from '../FormControlLabel';
import Radio from '../Radio';

const CreateUserForm = ({
  initialValues,
  onSubmitCallback,
  closeForm,
  typeCheckbox,
  cancelButton,
  showCoBuyerForm,
  toggleCoBuyerForm,
  isCoBuyerAllowed,
  inPayment,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const coBuyerMessageId = showCoBuyerForm ? 'removeCoBuyer' : 'addCoBuyer';

  const coSellerMessageId = showCoBuyerForm ? 'removeCoSeller' : 'addCoSeller';

  const getValidateType = () => {
    // If this is Add buyer form
    if (isCoBuyerAllowed) {
      const clients = showCoBuyerForm
        ? [clientTypes.buyer, clientTypes.coBuyer]
        : [clientTypes.buyer];
      return clients;
    }

    // If this Add Co-buyer form
    return [clientTypes.coBuyer];
  };
  const validateType = getValidateType();

  return (
    <Grid container>
      <Formik
        initialValues={initialValues}
        validate={validate(validateType, intl)}
        onSubmit={(values, { setSubmitting }) => {
          onSubmitCallback({
            ...values,
            setSubmitting,
            isCoBuyer: showCoBuyerForm,
            closeForm,
          });
        }}
      >
        {({
          values, errors, touched, handleChange, handleBlur, isSubmitting,
        }) => (
          <Form className={classes.form}>
            <Box pt={1} pb={1}>
              <Box pt={1} pb={1}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {values.type === 'buyer' ? (
                      <FormattedMessage {...messages.addCoBuyer} />
                    ) : (
                      <FormattedMessage {...messages.addCoSeller} />
                    )}
                  </FormLabel>
                  {typeCheckbox && (
                  <FormikRadioGroup name="type">
                    <FormControlLabel
                      value="buyer"
                      control={<Radio color="secondary" />}
                      label={intl.formatMessage(messages.buyer)}
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      value="seller"
                      control={<Radio color="secondary" />}
                      label={intl.formatMessage(messages.seller)}
                      onChange={handleChange}
                    />
                  </FormikRadioGroup>
                  )}
                </FormControl>
              </Box>
              <ClientFields
                clientType={isCoBuyerAllowed ? clientTypes.buyer : clientTypes.coBuyer}
                values={values}
                touched={touched}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                inPayment={inPayment}
              />
              {isCoBuyerAllowed && (
                <>
                  <Box display="flex" my={2}>
                    <Button color="primary" onClick={toggleCoBuyerForm} disabled={isSubmitting}>
                      {values.type === 'buyer' ? <FormattedMessage {...messages[coBuyerMessageId]} /> : <FormattedMessage {...messages[coSellerMessageId]} />}
                    </Button>
                  </Box>
                  {showCoBuyerForm && (
                  <ClientFields
                    clientType={clientTypes.coBuyer}
                    values={values}
                    touched={touched}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  )}
                </>
              )}
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    text={intl.formatMessage(messages.save)}
                  />
                </Grid>
                {cancelButton && (
                <Grid item>
                  <Button variant="contained" onClick={closeForm} size="large">
                    {intl.formatMessage(messages.cancel)}
                  </Button>
                </Grid>
                )}
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

CreateUserForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmitCallback: PropTypes.func.isRequired,
  closeForm: PropTypes.func,
  typeCheckbox: PropTypes.bool,
  cancelButton: PropTypes.bool,
  showCoBuyerForm: PropTypes.bool,
  toggleCoBuyerForm: PropTypes.func,
  isCoBuyerAllowed: PropTypes.bool,
  inPayment: PropTypes.bool,
};

CreateUserForm.defaultProps = {
  closeForm: () => {},
  typeCheckbox: false,
  cancelButton: false,
  showCoBuyerForm: false,
  toggleCoBuyerForm: () => {},
  isCoBuyerAllowed: false,
  inPayment: false,
};

export default CreateUserForm;
