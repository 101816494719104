import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  socialButton: {
    padding: theme.spacing(1),
  },
  socialIcon: {
    width: '20px',
    height: '20px',
  },
}));

export default useStyles;
