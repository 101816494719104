// does not include `process.env` to allow webpack env loader replacing them
function getBrowserEnv(key) {
  return global.env && global.env[key];
}

export default {
  common: {
    defaultLocale: 'en',
    supportedLocales: ['en'],
  },
  sentry: {
    dsn: getBrowserEnv('SENTRY_DSN') || process.env.REACT_APP_SENTRY_DSN,
  },
  interCom: {
    appId: getBrowserEnv('INTERCOM_API_ID') || process.env.REACT_APP_INTERCOM_API_ID,
  },
};
