const tableRows = [
  'homePrice',
  'downPaymentDashboard',
  'loanAmount',
  'percentLoanToValue',
  'desiredPayment',
  'principalInterest',
  'taxesInsurance',
  'mortgageInsurance',
  'totalEstimatedPayment',
  'betweenWithDesired',
  'loanOrigFee',
  'administrationFee',
  'appraisalTitlePolicy',
  'otherFees',
  'estimateClosingCosts',
  'prepaidInterest15days',
  'prepaidPropIns14days',
  'prepaidTaxes2mo',
  'prepaidMortIns1mo',
  'estPrepaids',
  'fundsRequiredToCloseABC',
  'fundsAvailableToClose',
  'betterOrWorseFundsToClose',
  'requiredIncome',
];

// eslint-disable-next-line max-len
const sortCalculationEntries = (calculationEntries) => tableRows.reduce((sortedCalculationEntries, currentRow) => {
  const foundRow = calculationEntries.find((entry) => entry[0] === currentRow);

  if (foundRow) {
    return [...sortedCalculationEntries, [currentRow, foundRow[1]]];
  }


  return sortedCalculationEntries;
}, []);

export default sortCalculationEntries;
