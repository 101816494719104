import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../common/authContext';
import Skeleton from './Skeleton';
import { TenantContext } from '../../common/tenantContext';

export default function withRouteProtection(Route) {
  function RouteProtection(props) {
    const { authUser, accountLoaded } = useContext(AuthContext);
    const { tenantLoaded } = useContext(TenantContext);
    if (accountLoaded && !authUser?._id) {
      return <Redirect to="/" />;
    }

    if (!accountLoaded || !tenantLoaded) {
      return <Skeleton/>;
    }

    return <Route {...props} />;
  }

  return RouteProtection;
}
