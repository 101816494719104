import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import SignInScreen from '../../components/User/SignInScreen';
import { AuthContext } from '../../common/authContext';
import { getAccount, login, patchAccount } from '../../services/account.service';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any
}

export default function SignInScreenContainer({ history }: Props) {
  const [error, setError] = useState({});
  const {authUser, setAuthUser} = useContext(AuthContext);
  
  const handleSignIn = useCallback(async ({ email, password }: {email: string, password: string}) => {
    try {
      const token = await login({email, password});
      const account = await getAccount(token.id);
      account.token = token.access_token;

      if (token?.migrated === false) {
        await patchAccount(account._id, {password});
      }
      
      setAuthUser(account);

      history.push('/welcome');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError({message: err?.message ?? err});
    }
  }, [history]);

  if (authUser) {
    return <Redirect to="/welcome" />;
  }

  return (
    <SignInScreen onSignIn={handleSignIn} error={error} />
  );
}

SignInScreenContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
