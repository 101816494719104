import React, { useState } from 'react';
import DashboardScreen from '../../components/User/DashboardScreen';
import { deleteClient, updateClient } from '../../services/client.service';

const initialClients = {
  potential: [],
  active: [],
  'under contract': [],
  closed: []
};

export default function DashboardScreenContainer() {
  const [sortBy, setSortBy] = useState('newest');
  const [clientsColumns, setClientsColumns] = useState(initialClients);

  const updateCard = async (updatebleClient) => {
    await updateClient(updatebleClient._id, updatebleClient);
    if (updatebleClient.firstName) {
      setClientsColumns({
        ...clientsColumns,
        [updatebleClient.status]: clientsColumns[updatebleClient.status].map((item) => {
          if (item._id === updatebleClient._id) {
            // eslint-disable-next-line no-param-reassign
            return { ...item, ...updatebleClient };
          }
          return item;
        }),
      });
    }
  };

  const handleChangeSortBy = (value) => {
    setSortBy(value);
  };

  const deleteCard = async (clientId) => {
    await deleteClient(clientId);
  };

  return (
    <DashboardScreen
      clientsColumns={clientsColumns}
      setClientsColumns={setClientsColumns}
      sortBy={sortBy}
      onChangeSortBy={handleChangeSortBy}
      onUpdateCard={updateCard}
      deleteCard={deleteCard}
    />
  );
}
