import { Client } from '../models/client.model';
import { getToken, validateAndExtractData } from './api.base.service';

export const getClient = async (clientId: string): Promise<Client> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}client/${clientId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      }
    }
  );
  const client: Client = await validateAndExtractData(response);
  return client;
};

export const getClients = async (
  realtorId: string,
  tenantId: string,
  status: string,
  sort?: number,
  types?: string,
  archive?: boolean,
): Promise<Client[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}client?realtorId=${realtorId}&tenantId=${tenantId}&status=${status}&isPrincipal=true&sort=${sort}&types=${types}&archive=${archive}`,
    {
      method: 'GET',
      
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      }
    }
  );
  const clients: Client[] = await validateAndExtractData(response);
  return clients?.map(c => ({...c, createdAt: new Date(String(c.createdAt))})) ?? [];
};

export const updateClient = async (
  clientId: string,
  clientData: Partial<Client>
): Promise<Client> => {
  const data = {...clientData};
  delete data._id;
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}client/${clientId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      },
      body: JSON.stringify(data)
    }
  );
  const client: Client = await validateAndExtractData(response);
  return client;
};

export const deleteClient = async (clientId: string): Promise<boolean> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}client/${clientId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      }
    }
  );
  const removed: { removed: boolean } = await validateAndExtractData(response);
  return removed?.removed ?? false;
};

export const createClient = async (
  clientData: Partial<Client>
): Promise<Client> => {
  const response = await fetch(
    `${process.env.REACT_APP_REAL_GENIUS_REST}client`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      },
      body: JSON.stringify(clientData)
    }
  );
  const client: Client = await validateAndExtractData(response);
  return client;
};
