import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import useStyles from './styles';

function DefaultCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="primary"
      checkedIcon={<span className={classes.checkedIcon} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

export default DefaultCheckbox;
