import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'; 
import clsx from 'clsx';
import InputAdornment from '../../Common/InputAdornment';
import Box from '../../Common/Box';
import Grid from '../../Common/Grid';
import SubmitButton from '../../Common/SubmitButton';
import messages from './messages';
import useStyles from './styles';
import ThouthandFormat from '../PaymentFormats/ThouthandFormat';
import PercentFormat from '../PaymentFormats/PercentFormat';
/* eslint-disable max-len */
import FormControl from '../../Common/FormControl';
import InputLabel from '../../Common/InputLabel';
import FormHelperText from '../../Common/FormHelperText';
import InputBase from '../../Common/InputBase';
import Typography from '../../Common/Typography';
import validate from './validate';

export default function PaymentCalculationFormSeller({ initialValues, onCalculate }) {
  const classes = useStyles();
  const intl = useIntl();
  const [listDate, setListDate] = useState(null);
  const [offerDate, setOfferDate] = useState(null);
  const [closeDate, setCloseDate] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!initialValues?.closeDate) {
      setError('Close Date is required filled');
    } else {
      setError(null);
      setCloseDate(initialValues.closeDate);
      setListDate(initialValues.listDate);
      setOfferDate(initialValues.offerDate);
    }
  }, [initialValues]);

  const buttonDisabled = (errors) => {
    return JSON.stringify(errors) !== '{}' || !!error;
  }

  return (
    <Formik
        validate={validate(intl)}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          if (!closeDate) {
            setSubmitting(false);
            setError('Close Date is required filled');
            return;
          }
          setError(null);
          const valuesCopy = { ...values };
          valuesCopy.closeDate = closeDate;
          valuesCopy.offerDate = offerDate;
          valuesCopy.listDate = listDate;
          valuesCopy.comfortablePrice = [values.min, values.max];
          onCalculate({ ...valuesCopy, setSubmitting });
        }}
      >
        {({
          isSubmitting, values, handleChange, errors,
        }) => (
          <Form>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                <Grid item md={4} sm={6}>
                  <FormControl fullWidth error={errors.min}>
                    <InputLabel className={classes.inputLabel} shrink required>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.listPrice} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.min}
                      name="min"
                      onChange={handleChange}
                      inputComponent={ThouthandFormat}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      placeholder="0"
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        step: 50,
                      }}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.min,
                      })}
                    />
                    <FormHelperText>{errors.min && errors.min}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl fullWidth error={errors.max}>
                    <InputLabel className={classes.inputLabel} shrink required>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.offerPrice} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.max}
                      name="max"
                      onChange={handleChange}
                      inputComponent={ThouthandFormat}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      placeholder="0"
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        step: 50,
                      }}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.max,
                      })}
                    />
                    <FormHelperText>{errors.max && errors.max}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.listingNumber} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.listingNumber}
                      name="listingNumber"
                      onChange={handleChange}
                      type="text"
                      placeholder="0"
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.listingNumber,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} sm={6}>
                  <DatePicker
                    margin="normal"
                    id="listDate"
                    label={intl.formatMessage(messages.listDate)}
                    format="dd-LLL-yyyy"
                    value={listDate}
                    onChange={(date) => setListDate(date)}
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <DatePicker
                    margin="normal"
                    id="offerDate"
                    label={intl.formatMessage(messages.offerDate)}
                    format="dd-LLL-yyyy"
                    value={offerDate}
                    onChange={(date) => setOfferDate(date)}
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl fullWidth error={!!error}>
                    <DatePicker
                      margin="normal"
                      id="closeDate"
                      label={intl.formatMessage(messages.closeDate)}
                      format="dd-LLL-yyyy"
                      value={closeDate}
                      onChange={(date) => {
                        if (date) setError(null);
                        setCloseDate(date);
                      }}
                    />
                    <FormHelperText>{error && error}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.propertyAdress} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.propertyAdress}
                      name="propertyAdress"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.propertyAdress,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth error={errors.agentCommission}>
                    <InputLabel className={classes.inputLabel} shrink required>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.agentCommission} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.agentCommission}
                      name="agentCommission"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.agentCommission,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      inputComponent={PercentFormat}
                    />
                    <FormHelperText>
                      {errors.agentCommission && errors.agentCommission}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.city} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.zip,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.state} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.state}
                      name="state"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.state,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.zip} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.zip}
                      name="zip"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.zip,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth error={errors.estimateBalanceOne}>
                    <InputLabel className={classes.inputLabel} shrink required>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.estimateBalanceOne} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.estimateBalanceOne}
                      name="estimateBalanceOne"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.estimateBalanceOne,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      inputComponent={ThouthandFormat}
                    />
                    <FormHelperText>
                      {errors.estimateBalanceOne && errors.estimateBalanceOne}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth error={errors.estimateBalanceTwo}>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.estimateBalanceTwo} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.estimateBalanceTwo}
                      name="estimateBalanceTwo"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.estimateBalanceTwo,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      inputComponent={ThouthandFormat}
                    />
                    <FormHelperText>
                      {errors.estimateBalanceTwo && errors.estimateBalanceTwo}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth error={errors.otherItemOne}>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.otherItem} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.otherItemOne}
                      name="otherItemOne"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.otherItemOne,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      inputComponent={ThouthandFormat}
                    />
                    <FormHelperText>{errors.otherItemOne && errors.otherItemOne}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth error={errors.otherItemTwo}>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.otherItem} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.otherItemTwo}
                      name="otherItemTwo"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.otherItemTwo,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      inputComponent={ThouthandFormat}
                    />
                    <FormHelperText>{errors.otherItemTwo && errors.otherItemTwo}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.allowanceOther} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.allowanceOtherOne}
                      name="allowanceOtherOne"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.allowanceOtherOne,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      inputComponent={ThouthandFormat}
                      defaultValue={0}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.allowanceOther} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.allowanceOtherTwo}
                      name="allowanceOtherTwo"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.allowanceOtherTwo,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      inputComponent={ThouthandFormat}
                      defaultValue={0}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      <Typography variant="caption">
                        <FormattedMessage {...messages.allowanceOther} />
                      </Typography>
                    </InputLabel>
                    <InputBase
                      value={values.allowanceOtherThree}
                      name="allowanceOtherThree"
                      onChange={handleChange}
                      className={clsx(classes.inputBase, {
                        [classes.inputBaseError]: errors.allowanceOtherThree,
                      })}
                      inputProps={{
                        step: 50,
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      inputComponent={ThouthandFormat}
                      defaultValue={0}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box textAlign="center" mt={3}>
                <SubmitButton
                  disabled={buttonDisabled(errors)}
                  isSubmitting={isSubmitting}
                  text={intl.formatMessage(messages.clickCalculate)}
                />
              </Box>
            </MuiPickersUtilsProvider>
          </Form>
        )}
    </Formik>
  );
}

PaymentCalculationFormSeller.propTypes = {
  onCalculate: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};
