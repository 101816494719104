import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import TextField from '../../Common/TextField';
import Button from '../../Common/Button';
import Paper from '../../Common/Paper';
import Grid from '../../Common/Grid';
import Typography from '../../Common/Typography';
import Box from '../../Common/Box';
import Snackbar from '../../Common/Snackbar';
import Alert from '../../Common/Alert';
import Header from '../Header';
import messages from './messages';
import useStyles from './styles';
import Container from '../../Common/Container';


export default function SignUpScreen({ onSignUp, error, tenantName }) {
  const [isOpen, setIsOpen] = useState(false);

  const initialValues = {
    name: '',
    company: '',
    tenant: tenantName,
    email: '',
    password: '',
  };

  useEffect(() => {
    if (error?.message) {
      setIsOpen(true);
    }
  }, [error]);

  const classes = useStyles();
  const intl = useIntl();

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = intl.formatMessage(messages.requiredEmail);
    } else if (values.email.indexOf('@') === -1) {
      errors.email = intl.formatMessage(messages.invalidEmail);
    }
    if (!values.password) {
      errors.password = intl.formatMessage(messages.requiredPassword);
    }
    if (!values.name) {
      errors.name = intl.formatMessage(messages.requiredName);
    }
    return errors;
  };

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <Box>
      <Header />
      <Box position="relative" pb={8}>
        <Container maxWidth="xl" component="div" disableGutters>
          <Grid className={classes.root} container justifyContent="center" alignItems="center">
            <Grid item xs={8} sm={6} md={4}>
              <Paper className={classes.paper} elevation={0} square>
                <Typography variant="h5">
                  <Box mb={2}>
                    <FormattedMessage {...messages.title} />
                  </Box>
                </Typography>
                <Typography variant="h6">
                  <Box mb={2}>
                    <FormattedMessage {...messages.text} />
                  </Box>
                </Typography>
                <Typography variant="subtitle1">
                  <Box mb={3}>
                    <FormattedMessage {...messages.benefits} />
                  </Box>
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={(values, { setSubmitting }) => {
                    onSignUp(values);
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <div>
                        <TextField
                          label={intl.formatMessage(messages.name)}
                          variant="outlined"
                          autoComplete="off"
                          name="name"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          error={!!errors.name && touched.name}
                          helperText={errors.name && touched.name && errors.name}
                        />
                      </div>
                      <div>
                        <TextField
                          label={intl.formatMessage(messages.company)}
                          variant="outlined"
                          autoComplete="off"
                          name="company"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company}
                          error={!!errors.company && touched.company}
                          helperText={errors.company && touched.company && errors.company}
                        />
                      </div>
                      <div>
                        <TextField
                          label={intl.formatMessage(messages.tenant)}
                          variant="outlined"
                          autoComplete="off"
                          name="tenant"
                          fullWidth
                          disabled
                          value={values.tenant}
                        />
                      </div>
                      <div>
                        <TextField
                          label={intl.formatMessage(messages.email)}
                          variant="outlined"
                          autoComplete="off"
                          name="email"
                          fullWidth
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={!!errors.email && touched.email}
                          helperText={errors.email && touched.email && errors.email}
                        />
                      </div>
                      <div>
                        <TextField
                          label={intl.formatMessage(messages.password)}
                          variant="outlined"
                          autoComplete="off"
                          name="password"
                          fullWidth
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          error={!!errors.password && touched.password}
                          helperText={errors.password && touched.password && errors.password}
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <FormattedMessage {...messages.title} />
                      </Button>
                    </form>
                  )}
                </Formik>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" elevation={6} variant="filled" onClose={handleClose}>
          {error.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

SignUpScreen.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  error: PropTypes.object,
  tenantName: PropTypes.string.isRequired,
};

SignUpScreen.defaultProps = {
  error: {},
};
