import { defineMessages } from 'react-intl';

export default defineMessages({
  newest: {
    id: 'Unknown.DashboardScreen.newest',
    defaultMessage: 'Newest',
  },
  oldest: {
    id: 'Unknown.DashboardScreen.oldest',
    defaultMessage: 'Oldest',
  },
  buyers: {
    id: 'Unknown.DashboardScreen.buyers',
    defaultMessage: 'Buyers',
  },
  sellers: {
    id: 'Unknown.DashboardScreen.sellers',
    defaultMessage: 'Sellers',
  },
  sortBy: {
    id: 'Unknown.DashboardScreen.sortBy',
    defaultMessage: 'Sort by',
  },
  buyer: {
    id: 'Unknown.DashboardScreen.buyer',
    defaultMessage: 'buyer',
  },
  seller: {
    id: 'Unknown.DashboardScreen.seller',
    defaultMessage: 'seller',
  },
  addClient: {
    id: 'Unknown.DashboardScreen.addClient',
    defaultMessage: 'Add new client (Select one)',
  },
  deleteConfirmation: {
    id: 'Unknown.DashboardScreen.deleteConfirmation',
    defaultMessage: 'Delete this card. Are you sure?',
  },
  confirm: {
    id: 'Unknown.DashboardScreen.confirm',
    defaultMessage: 'Yes',
  },
  notConfirm: {
    id: 'Unknown.DashboardScreen.notConfirm',
    defaultMessage: 'No',
  },
  allClients: {
    id: 'Unknown.DashboardScreen.allClients',
    defaultMessage: 'All Clients',
  },
  addNew: {
    id: 'Unknown.DashboardScreen.addNew',
    defaultMessage: 'Add new client',
  },
  import: {
    id: 'Unknown.DashboardScreen.import',
    defaultMessage: 'Import',
  },
  potential: {
    id: 'Unknown.DashboardScreen.potential',
    defaultMessage: 'Potential',
  },
  active: {
    id: 'Unknown.DashboardScreen.active',
    defaultMessage: 'Active',
  },
  underContract: {
    id: 'Unknown.DashboardScreen.underContract',
    defaultMessage: 'Under Contract',
  },
  closed: {
    id: 'Unknown.DashboardScreen.closed',
    defaultMessage: 'Closed',
  },
  placeholder: {
    id: 'Unknown.DashboardScreen.searchName',
    defaultMessage: 'Search name',
  },
  showArchive: {
    id: 'Unknown.DashboardScreen.showArchive',
    defaultMessage: 'Show archived cards',
  },
});
