import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    marginTop: 'calc(100vh * 0.4)',
  },
  mainWrapper: {
    position: 'relative',
    minHeight: '100vh',
    paddingBottom: theme.spacing(17),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(26),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(30),
    },
  },
}));

export default useStyles;
