import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Common.SignInScreen.title',
    defaultMessage: 'Log In',
  },
  requiredEmail: {
    id: 'Common.SignInScreen.requiredEmail',
    defaultMessage: 'Email is required.',
  },
  requiredPassword: {
    id: 'Common.SignInScreen.requiredPassword',
    defaultMessage: 'Password is required.',
  },
  invalidEmail: {
    id: 'Common.SignInScreen.incorrectEmail',
    defaultMessage: 'The email is invalid',
  },
});
