import React, { useState, useEffect } from 'react';
import Alert from '../Alert';
import Snackbar from '../Snackbar';

const ErrorSnackbar = (userError) => {
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);

  useEffect(() => {
    if (userError) {
      setIsErrorSnackbarOpen(true);
    }
  }, [userError]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsErrorSnackbarOpen(false);
  };

  return userError.error ? (
    <Snackbar
      open={isErrorSnackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity="error" elevation={6} variant="filled" onClose={handleClose}>
        {userError.error}
      </Alert>
    </Snackbar>
  ) : null;
};

export default ErrorSnackbar;
