import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'Common.CreateUserForm.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Common.CreateUserForm.cancel',
    defaultMessage: 'Cancel',
  },
  addCoBuyer: {
    id: 'Common.CreateUserForm.addCoBuyer',
    defaultMessage: 'Add co-buyer',
  },
  addCoSeller: {
    id: 'Common.CreateUserForm.addCoSeller',
    defaultMessage: 'Add co-seller',
  },

  type: {
    id: 'Common.CreateUserForm.type',
    defaultMessage: 'Client type',
  },
  buyer: {
    id: 'Common.CreateUserForm.buyer',
    defaultMessage: 'buyer',
  },
  seller: {
    id: 'Common.CreateUserForm.seller',
    defaultMessage: 'seller',
  },
  removeCoBuyer: {
    id: 'Common.CreateUserForm.removeCoBuyer',
    defaultMessage: 'remove co-buyer',
  },
  removeCoSeller: {
    id: 'Common.CreateUserForm.removeCoSeller',
    defaultMessage: 'remove co-seller',
  },
});
