import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Header from '../../../containers/User/HeaderContainer';
import Grid from '../../Common/Grid';
import Paper from '../../Common/Paper';
import Container from '../../Common/Container';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import messages from './messages';
import useStyles from './styles';
import Button from '../../Common/Button';
import Footer from '../../Unknown/Footer';
import { TenantContext } from '../../../common/tenantContext';
import { AnalyticsContext } from '../../../common/analyticsContext';
import { AnalyticEventNames } from '../../../common/analyticEventNames.enum';
import { AuthContext } from '../../../common/authContext';

export default function WelcomeScreen() {
  const classes = useStyles();
  const { tenant } = useContext(TenantContext);
  const { authUser } = useContext(AuthContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { _id: tenantId, logoUrl } = tenant ?? {};
  const [isContextLoad, setIsContextLoad] = useState(false);

  useEffect(() => {
    if (tenantId) {
      setIsContextLoad(true);
    } else {
      setIsContextLoad(false);
    }
  }, [tenantId]);

  return (
    <>
      {isContextLoad
        && (
          <>
            <Header />
            <Container maxWidth="xl" component="div" disableGutters>
              <Grid className={classes.root} container justifyContent="center" alignItems="center">
                <Grid item xs={8} sm={6} md={4}>
                  <Paper className={classes.paper} elevation={0} square>
                    <Typography variant="h5">
                      <Box mb={2}>
                        <FormattedMessage {...messages.title} />
                      </Box>
                    </Typography>
                    <Grid container item style={{ marginBottom: 30 }}>
                      <img style={{ width: '100%', overflow: 'hidden' }} src={logoUrl} alt="Bank logo" />
                    </Grid>
                    <Box display="flex" justifyContent="space-between">
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`/dashboard/${tenant.name}`}
                        onClick={() => {
                          trackEvent(AnalyticEventNames.RealtorInteraction, {realtorId: authUser?._id, tenantId, action: 'View clients'})
                        }}
                      >
                        <FormattedMessage {...messages.viewClients} />
                      </Button>
                      <Button
                        size="large"
                        color="secondary"
                        variant="contained"
                        component={Link}
                        to="/clients/create"
                        onClick={() => {
                          trackEvent(AnalyticEventNames.RealtorInteraction, {realtorId: authUser?._id, tenantId, action: 'Add new client'})
                        }}
                      >
                        <FormattedMessage {...messages.addClient} />
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </>
        )}
    </>
  );
}
