import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tenant } from '../models/tenant.model';
import { getTenant } from '../services/tenant.service';
import { AuthContext } from './authContext';
import { DateTime } from 'luxon';
import { AnalyticsContext } from './analyticsContext';
import { AnalyticEventNames } from './analyticEventNames.enum';

export const TenantContext = React.createContext<{tenant: Tenant | null, tenantLoaded: boolean, setTenant: React.Dispatch<React.SetStateAction<Tenant | null>>}>({
  tenant: null,
  tenantLoaded: false,
  setTenant: () => {}
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TenantProvider = ({ children }: {children: any}) => {
  const { authUser } = useContext(AuthContext);
  const { trackEvent, setCurrentTenant } = useContext(AnalyticsContext);
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [tenantLoaded, setTenantLoaded] = useState<boolean>(false);
  const value = {tenant, tenantLoaded, setTenant};

  useEffect(() => {

    const fetchTenant = async () => {
      setTenantLoaded(false);
      if (authUser?.tenants?.[0]) {
        const tenant = await getTenant(authUser.tenants[0]);
        setTenant(tenant);
        setTenantLoaded(true);
        setCurrentTenant(tenant);

        const lastTrackOfLogin: {date: string, tenantId: string} = JSON.parse(localStorage.getItem('realgenius:lastLoginEvent') ?? '{}');
        const currentDate = DateTime.now().toFormat('MM/dd/yyyy');
        if (lastTrackOfLogin?.date !== currentDate || lastTrackOfLogin.tenantId !== tenant._id) {
          trackEvent(AnalyticEventNames.Login, {email: authUser.email, tenantName: tenant.name, tenantId: tenant._id});
          localStorage.setItem('realgenius:lastLoginEvent', JSON.stringify({date: currentDate, tenantId: tenant._id}));
        }
      }
    }
    fetchTenant()
        .catch(console.error);
  }, [authUser?.tenants]);

  return (
    <>
      <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
    </>
  );
};

TenantProvider.propTypes = {
  children: PropTypes.object.isRequired
};
