import messages from './messages';

const validate = (clients, intl) => (values) => (
  clients.reduce((errors, client) => {
    const clientErrors = {};
    if (!values[client].email) {
      clientErrors.email = intl.formatMessage(messages.requiredEmail);
    } else if (values[client].email.indexOf('@') === -1) {
      clientErrors.email = intl.formatMessage(messages.invalidEmail);
    }
    if (!values[client].phone) {
      clientErrors.phone = intl.formatMessage(messages.requiredPhone);
    }
    if (!values[client].firstName) {
      clientErrors.firstName = intl.formatMessage(messages.requiredFirstName);
    }
    if (!values[client].lastName) {
      clientErrors.lastName = intl.formatMessage(messages.requiredLastName);
    }
    if (Object.keys(clientErrors).length > 0) {
      return { ...errors, [client]: clientErrors };
    }
    return errors;
  }, {})
);

export default validate;
