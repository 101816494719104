import React from 'react';
import PropTypes from 'prop-types';
import { AnalyticEventNames } from './analyticEventNames.enum';
import { saveEvent } from '../services/analytic-event.service';
import { Tenant } from '../models/tenant.model';

export const AnalyticsContext = React.createContext<{
  trackEvent: (eventName: AnalyticEventNames, properties: object) => void;
  setCurrentTenant: (tenant?: Tenant) => void;
}>({
  trackEvent: () => {},
  setCurrentTenant: () => {}
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AnalyticsProvider = ({ children }: { children: any }) => {
  let tenant: Tenant | undefined;

  const trackEvent = async (
    eventName: AnalyticEventNames,
    properties: object
  ) => {
    saveEvent(eventName, {
      tenantId: tenant?._id,
      ...properties
    });
  };

  const setCurrentTenant = (newTenant?: Tenant) => {
    tenant = newTenant;
  };

  const value = { trackEvent, setCurrentTenant };

  return (
    <>
      <AnalyticsContext.Provider value={value}>
        {children}
      </AnalyticsContext.Provider>
    </>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.object.isRequired
};
