import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: 600,
  },
  estimatedFields: {
    fontWeight: 600,
  },
  dangerText: {
    color: theme.palette.error.main,
  },
  requiredIncomeStyle: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  applyButton: {
    backgroundColor: theme.palette.error.main,
  },
  tableHeadCell: {
    paddingBottom: theme.spacing(0.925),
    paddingTop: theme.spacing(0.925),
  },
  heading: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  tableHead: {
    backgroundColor: theme.palette.secondary.tableHeadGray,
  },
  collapsParent: {
    padding: '0!important',
  },
  collapsableRow: {
    borderBottom: `1px solid ${theme.palette.secondary.tableHeadGray}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  collapsableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: '12px',
    borderBottom: 'none',
  },
  collapsedBody: {
    '& tr': {
      '&:last-child': {
        '& td': {
          borderBottom: 'none',
        },
      },
    },
  },
  active: {
    animation: 'none !important',
  },
  notImportant: {
    '& td': {
      transformOrigin: 'top',
      animation: '$animateTable 400ms linear',
      '& button': {
        animation: '$buttonHide 400ms linear',
        '& svg': {
          animation: '$iconHide 400ms linear',
        },
      },
    },
  },
  '@keyframes animateTable': {
    '0%': {
      opacity: 0,
      padding: 0,
      lineHeight: 0,
      transform: 'scaleY(0)',
      borderBottom: 'none',
    },
    '49%': {
      transform: 'scaleY(1)',
      padding: 'inhrit',
      borderBottom: 'none',
      opacity: 0,
    },
    '50%': {
      opacity: 0,
      borderBottom: `1px solid ${theme.palette.secondary.tableHeadGray}`,
      transform: 'scaleY(1)',
      padding: 'inhrit',
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes buttonHide': {
    '0%': {
      padding: '0px',
    },
    '100%': {
      padding: '3px',
    },
  },
  '@keyframes iconHide': {
    '0%': {
      height: '0px',
    },
    '100%': {
      height: 'inherit',
    },
  },
  longWord: {
    'overflow-wrap': 'break-word',
    'word-wrap': 'break-word',
    '-webkit-hyphens': 'auto',
    '-ms-hyphens': 'auto',
    '-moz-hyphens': 'auto',
    'word-break': 'break-all',
    hyphens: 'auto',
  },
}));

export default useStyles;
