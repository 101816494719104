import { createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';

const primaryFontFamily = 'Montserrat';

const defaultTheme = createTheme();

export const colors = {
  primary: '#3F51B5',
  lightGray: '#D8D6DE',
  darkGray: '#C4C4C4',
  primaryLight: '#E7E9F3',
  checkboxDisabled: 'rgba(206,217,224,.5)',
  captionColor: '#B9B9C3',
  invitationWhite: '#E5E5E5',
  textBlack: '#1D1D28',
  placeholderGray: '#B9B9C3',
  tableHeadGray: '#F3F2F7',
  errorMain: '#F22424',
  successLight: 'rgba(40, 199, 111, 0.4)',
  avatarBoxOrange: 'rgb(241, 101, 7)',
};

export const shadows = {
  lightBlue: '0px 0px 10px rgba(61, 162, 212, 0.65)',
  paperShadow: '0px 0px 4px 4px rgba(193, 193, 193, 0.2)',
};

const theme = createTheme({
  typography: {
    fontFamily: primaryFontFamily,
  },
  palette: {
    cards: {
      buyer: blue['500'],
      seller: green['500'],
      contrastText: '#ffffff',
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      lightGray: colors.lightGray,
      checkboxDisabled: colors.checkboxDisabled,
      main: defaultTheme.palette.secondary.main,
      darkGray: colors.darkGray,
      invitationWhite: colors.invitationWhite,
      placeholderGray: colors.placeholderGray,
      tableHeadGray: colors.tableHeadGray,
      avatarBoxOrange: colors.avatarBoxOrange,
    },
    textPrimary: {
      textBlack: colors.textBlack,
    },
    success: {
      light: colors.successLight,
      main: defaultTheme.palette.success.main,
      dark: defaultTheme.palette.success.dark,
    },
    error: {
      main: colors.errorMain,
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        borderColor: colors.lightGray,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.lightGray,
        },
        '& input': {
          '&::placeholder': {
            color: colors.placeholderGray,
            fontSize: '12px',
            lineHeight: 1.5,
            fontWeight: 400,
            opacity: 1,
          },
        },
      },
    },
    MuiTypography: {
      h1: {
        fontWeight: 600,
        lineHeight: 1.31,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 16,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 18,
        },
      },
      h3: {
        fontWeight: 600,
        lineHeight: '61px',
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 35,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 50,
        },
      },
      h6: {
        fontWeight: 400,
        lineHeight: 1.31,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 16,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 18,
        },
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.31,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 14,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 16,
        },
      },
      body2: {
        fontWeight: 600,
        lineHeight: 17 / 16,
      },
      caption: {
        fontWeight: 400,
        lineHeight: 0.9,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 11,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 12,
        },
      },
      h4: {
        fontSize: 30,
        lineHeight: 2.31,
        fontWeight: 600,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 25,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 30,
        },
      },
      h5: {
        lineHeight: 1.87,
        fontWeight: 400,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 20,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 25,
        },
      },
      h2: {
        fontSize: 25,
        lineHeight: 1.87,
        fontWeight: 600,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 20,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 25,
        },
      },
      subtitle2: {
        lineHeight: 1.31,
        fontWeight: 400,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 12,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 14,
        },
      },
      subtitle1: {
        lineHeight: 1.31,
        fontWeight: 500,
        [defaultTheme.breakpoints.up('xs')]: {
          fontSize: 11,
        },
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 16,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        boxShadow: 'none',
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: colors.primary,
          boxShadow: shadows.lightBlue,
        },
      },
      sizeLarge: {
        letterSpacing: ' 0.4px',
        fontSize: 14,
        lineHeight: 17 / 16,
        padding: defaultTheme.spacing(1.25, 2.75),
      },
      outlinedPrimary: {
        border: `1px solid ${colors.primary}`,
      },
    },
    MuiPaper: {
      elevation0: {
        boxShadow: shadows.paperShadow,
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: shadows.paperShadow,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${colors.tableHeadGray}`,
        [defaultTheme.breakpoints.down('sm')]: {
          padding: defaultTheme.spacing(1.800, 0.5),
        },
        [defaultTheme.breakpoints.up('sm')]: {
          padding: defaultTheme.spacing(1.800),
        },
      },
      sizeSmall: {
        [defaultTheme.breakpoints.down('sm')]: {
          padding: defaultTheme.spacing(0.75, 1),
        },
        [defaultTheme.breakpoints.up('sm')]: {
          padding: defaultTheme.spacing(0.75, 3, 0.75, 2),
        },
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
});

export default theme;
