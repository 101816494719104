import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import Box from '../Box';
import Typography from '../Typography';

const TypeAvatar = ({ type, classes, inCard }) => {
  const theme = useTheme();
  return (
    <Box
      borderRadius="50%"
      width={inCard ? '30px' : '50px'}
      height={inCard ? '30px' : '50px'}
      color="white"
      bgcolor={type === 'seller' ? theme.palette.secondary.avatarBoxOrange : theme.palette.primary.main}
      alignItems="center"
      justifyContent="center"
      display="flex"
      className={classes}
    >
      <Typography variant={inCard ? 'body2' : 'h5'} component="span">
        {type === 'seller' ? 'S' : 'B'}
      </Typography>
    </Box>
  );
};

export default TypeAvatar;

TypeAvatar.propTypes = {
  type: PropTypes.string.isRequired,
  classes: PropTypes.string,
  inCard: PropTypes.bool,
};

TypeAvatar.defaultProps = {
  classes: '',
  inCard: false,
};
