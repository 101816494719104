import { defineMessages } from 'react-intl';

export default defineMessages({
    nextSteps: {
        id: 'Payment.PaymentPartners.nextSteps',
        defaultMessage: 'Next Steps'
    },
    lender: {
        id: 'Payment.PaymentPartners.lender',
        defaultMessage: 'Lender'
    },
    titleCo: {
        id: 'Payment.PaymentPartners.titleCo',
        defaultMessage: 'Title Co'
    },
    valuation: {
        id: 'Payment.PaymentPartners.valuation',
        defaultMessage: 'Valuation'
    }
});
